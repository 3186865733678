import React from 'react'
import { Mydatatable } from '../Master/Product/Mydatatable';

const Newmrn = () => {
    let header = [
        { "label": "Id", "field": "id" },
        { "label": " Company Name", "field": "companyname" },
        { "label": "MRN date", "field": "mrndate" },
        
        { "label": "City", "field":"cities" },
        { "label": "Taxable", "field": "grossamount" },
        { "label": "Tax", "field": "igst" },
        // { "label": "State", "field": "state_name" },
        { "label": "Grand Total  ", "field": "grandtotal" },
    //  { "label": "Remarks", "field": "remarks" },
    { "label": "Created Date", "field": "createddate" },
    { "label": "Created Time", "field": "createdtime" },
    { "label": "Created By", "field": "createbyname" },
    { "label": "Updated Date", "field": "updateddate" },
    { "label": "Updated Time", "field": "updatedtime" },
    { "label": "Updated By", "field": "updatedbyname" },
        { "label": "Action", "field": "action","subaction1":<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"/><circle cx="12" cy="12" r="3"/></svg> }
      ]; 



      const sumofkeys=[{"first":'grossamount'} ]
  return (
    <Mydatatable
    head={header}
    title={"Material Receipt Note"}
    axiostabledata={'mrn'}
    Add={"Add MRN"}
    Create={'/MaterialReceiptNoteCreate'}
    Edit={"/MaterialReceiptNoteView"}
    View={"/MaterialReceiptNoteView"}
    Sumofkeys={sumofkeys}
    />
  )
}

export default Newmrn