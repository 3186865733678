import axios from "axios";
import { MDBCard } from "mdbreact";
import React from "react";
import { Accordion, Button, Col, Form, Row, Table } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../services/Api";
import authHeader from "../services/auth-header";
import { useEffect } from "react";
import { useState } from "react";
import Swal from "sweetalert2";
import AuthService from "../services/auth.service";

export default function View() {
    const { id } = useParams(); const { pointindex } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const applyFontSizeTransition = (elementId) => {
        const element = document.getElementById(elementId);
        element.style.transition = 'font-size 2s';
        element.style.fontSize = '30px';
        element.style.fontWeight = '600';
        // font-weight: 600;
        setTimeout(() => {

            document.getElementById("typer1").classList.add('promote');
            element.style.fontSize = '24px';
        }, 1000);
    };
    // const{pointindex}=useParams();
    const [warehouse, setwarehouse] = useState('');
    const [supplier, setsupplier] = useState('');
    const [data, setData] = useState('');
    const [date, setDate] = useState('');
    const [date1, setDate1] = useState('');
    const [supplierSubContacts, setsupplierSubContacts] = useState('');
    const [branchData, setBranchData] = useState('');
    const [purchaseItems, setpurchaseItems] = useState([]);
    const navigate = useNavigate();
    const user = AuthService.getCurrentUser();
    const getPOData = () => {

        axios.get(BASE_URL + 'po/' + id, {
            headers: authHeader()
        }).then(res => {
            console.log("mello", res.data);
            setData(res.data);
            setDate(res.data.podate);
            setDate(res.data.referencedate);
            setwarehouse(res.data.warehouse);
            setsupplier(res.data.supplier);
            setBranchData(res.data.branch);
            setsupplierSubContacts(res.data.supplierSubContacts);
            setpurchaseItems(res.data.purchaseOrderItems);
            setDate1(res.data.referencedate)
        })
    };

    // const getPOData = ( ) => {
    //     axios.get( BASE_URL + '')
    // }

    // const getsupplierdeliverynotedata = ( ) => {
    //     axios.get(BASE_URL + 'sdn/' + id,{
    //         headers:authHeader()
    //     }).then(res=>{
    //         console.log("mello",res.d)
    //     })
    // }

    let date_value = new Date(date);
    const defaultValue = date_value.toLocaleDateString('en-CA');
    let date_value1 = new Date(date1);
    const defaultValue1 = date_value1.toLocaleDateString('en-CA');
    useEffect(() => {
        getPOData();
        // getsupplierdeliverynotedata();
    }, []);
    const getItem = (id) => {

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                setIsLoading(true);
                axios.delete(BASE_URL + 'po/' + id, {
                    headers: authHeader()
                }).then(res => {
                    Swal.fire(
                        'Deleted!',
                        'Your file has been deleted.',
                        'success'
                    )
                    navigate('/potable/${pointindex}')
                }).catch(error => {
                    console.error('Error adding  :', error);
                    if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Something went wrong!",
                            footer: `
                    <div>
                      <p id="issueText">Why do I have this issue?</p>
                      <ul>
                  
                      <li id="typer1">You might have an internet issue</li>
                      </ul>
                    </div>
                  `,
                            didOpen: () => {
                                applyFontSizeTransition('issueText');
                            }
                        });
                    }
                })

                    .finally(() => {
                        setIsLoading(false);
                    });


            }
        })
    };


    return (

        <MDBCard className="company">
            <div>
                {/* <h1>View Purchase Order</h1> */}
                {/* <div className='p-2 w-100 bd-highlight'><Link to={'/Supplier/Delivery/Note/Create/'+id}><button class="btn btn-sm btn-secondary">Supplier Delivery Note</button></Link></div> */}
                {/* <div className='p-2 w-100 bd-highlight'><Link to={'/SupplierDeliveryNoteCreate/'+id}><button class="btn btn-sm btn-secondary">Supplier Delivery Note</button></Link></div>

        <Link className='p-2 w-200 card-header card-title bd-highlight' to={`/poedit/${id}/${pointindex}`}><Button>Edit</Button></Link>
        <a className=' delete  btn' onClick={()=>getItem(id)}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"/><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"/><line x1="10" y1="11" x2="10" y2="17"/><line x1="14" y1="11" x2="14" y2="17"/></svg></a>

        <Link className='p-2 w-200 card-header card-title bd-highlight' to={`/potable/${pointindex}`}><Button>Back</Button></Link>

   
        {/* <Route path="/poedit/:id/:pointindex" element={<POEdit />} />
                  <Route path="/po/:id/:pointindex" element={<POView />} /> */}
                {/* {checkdata[0] === undefined?<div className='p-2 w-100 bd-highlight'> <Link to={'/PurchaseReturn/Create/'+id}> <button class="btn btn-sm btn-secondary">Purchase Return </button></Link></div>
         :null} */}
            </div>


            {data.status == 'converted' ? (
                <div className='d-flex bd-highlight'>
                    <div className="card-header card-title w-100">View Purchase Order</div>
                    <label className="form-label" style={{color:'green'}}>{data.status == 'converted' ? 'Converted':""}</label>
                   <Link className='p-2 w-200 card-header btn-sm card-title bd-highlight' to={`/potable/${pointindex}`}><Button className="btn-sm">Back</Button></Link>
                </div>
            ) :
                <div className='d-flex bd-highlight'>
                    <div className="card-header card-title w-100">View Purchase Order</div>
                    <label className="form-label" style={{color:'red'}}>{  'Pending' }</label>

                    {/* <button className='prox btn' onClick={()=>handleChang/e12()} ></button> */}
                    {/* <Link className='p-2 w-200 card-header  btn-sm card-title bd-highlight    ' onClick={()=>getItem(id)}><Button className="delete btn-sm"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"/><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"/><line x1="10" y1="11" x2="10" y2="17"/><line x1="14" y1="11" x2="14" y2="17"/></svg></Button></Link> */}
                    {user.roles[0] === 'ROLE_ADMIN' &&
                        <Link className='p-2 w-200 card-header card-title bd-highlight' variant="primary" style={{ width: 'auto' }} onClick={() => getItem(id)} disabled={isLoading}>

                            <Button className="btn-sm">  {isLoading ? 'Loading...' : 'Delete'}</Button>
                        </Link>
                    }
                    <Link className='p-2 w-200 card-header  btn-sm card-title bd-highlight' to={`/poedit/${id}/${pointindex}`}><Button className="btn-sm">Edit</Button></Link>
                    <Link className='p-2 w-200 card-header  btn-sm card-title bd-highlight' to={'/SupplierDeliveryNoteCreate/' + id} ><Button className="btn-sm">SDN</Button></Link>


                    <Link className='p-2 w-200 card-header btn-sm card-title bd-highlight' to={`/potable/${pointindex}`}><Button className="btn-sm">Back</Button></Link>
                </div>
            }








            <Form className="product1">
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Purchase Data</Accordion.Header>
                        <Accordion.Body>
                            <Row className="mb-1">
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Branch Name</Form.Label>
                                    <Form.Control type="text" value={branchData.branchname} autoComplete="off" readOnly />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">WareHouse Name</Form.Label>
                                    <Form.Control type="text" value={warehouse.name} autoComplete="off" readOnly />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Supplier Company</Form.Label>
                                    <Form.Control type="text" value={supplier.companyname} autoComplete="off" readOnly />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Contact Name</Form.Label>
                                    <Form.Control type="text" value={supplierSubContacts == null ? '' : supplierSubContacts.contactname} autoComplete="off" readOnly />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Date</Form.Label>
                                    <Form.Control type="date" value={defaultValue} autoComplete="off" />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Terms of Payment</Form.Label>
                                    <Form.Control type='text' value={data.paymentTerms} autoComplete="off" />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Voucher </Form.Label>
                                    <Form.Control type='text' value={data.voucherseries} name="voucherseries" autoComplete="off" />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Voucher Name</Form.Label>
                                    <Form.Control type='text' value={data.voucherseries} name="voucherseries" autoComplete="off" />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Reference No</Form.Label>
                                    <Form.Control type='text' value={data.referenceno} name="referenceno" autoComplete="off" />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Reference Date</Form.Label>
                                    <Form.Control type='date' value={defaultValue1} name="referencedate" autoComplete="off" />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Other Reference</Form.Label>
                                    <Form.Control type='text' value={data.otherrefernce} name="otherrefernce" autoComplete="off" />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Dispatched Through</Form.Label>
                                    <Form.Control type='text' value={data.dispatchedthrough} name="dispatchedthrough" autoComplete="off" />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Destination</Form.Label>
                                    <Form.Control type='text' value={data.destination} name="destination" autoComplete="off" />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Terms of Delivery</Form.Label>
                                    <Form.Control type='text' value={data.termsofdelivery} name="termsofdelivery" autoComplete="off" />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Declaration</Form.Label>
                                    <Form.Control type='text' value={data.declaration} name="declaration" autoComplete="off" />
                                </Form.Group>
                                {/* <Form.Group as={Col} md="4" controlId="formBasicName">
                            <Form.Label className="label">Type</Form.Label>
                            <Form.Select value={data.type} disabled>
                                <option value="b2b">B2B</option>
                                <option value="b2c">B2C</option>
                            </Form.Select>
                        </Form.Group> */}
                                {/* <Form.Group as={Col} md="8" controlId="formBasicName">
                            <Form.Label className="label">Owner</Form.Label>
                            <Form.Control type="text" autoComplete="off" />
                        </Form.Group> */}
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Status</Form.Label>
                                    <Form.Select value={data.status} disabled>
                                        <option value='true'>Open</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Tax Type</Form.Label>
                                    <Form.Select value={data.taxtype} disabled>
                                        <option>--Select--</option>
                                        <option value='withtax'>With Tax</option>
                                        <option value='withouttax'>Without Tax</option>
                                    </Form.Select>
                                </Form.Group>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                    {/* <Accordion.Item eventKey="1">
                <Accordion.Header>Billing Address</Accordion.Header>
                <Accordion.Body>
                    <Row className="mb-1">
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                            <Form.Label className="label">Supplier Address</Form.Label>
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="formBasicName">
                            <Form.Label className="label">Supplier Details</Form.Label>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                            <Form.Label className="label">Address : -</Form.Label>
                            <span>&nbsp;{supplier.address}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                            <Form.Label className="label">Name : -</Form.Label>
                            <span>&nbsp;{supplierSubContacts == null?"":supplierSubContacts.contactname}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                            <Form.Label className="label">City : -</Form.Label>
                            <span>&nbsp;{supplier.cities}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                            <Form.Label className="label">Phone No : -</Form.Label>
                            <span>&nbsp;{supplierSubContacts == null?"":supplierSubContacts.phoneno}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                            <Form.Label className="label">Country : -</Form.Label>
                            <span>&nbsp;{"India"}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                            <Form.Label className="label">Email Id : -</Form.Label>
                            <span>&nbsp;{supplierSubContacts == null?"":supplierSubContacts.email}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                            <Form.Label className="label">State : -</Form.Label>
                            <span>&nbsp;</span>
                        </Form.Group>
                    </Row>
                </Accordion.Body>
            </Accordion.Item> */}
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>Shipping Address</Accordion.Header>
                        <Accordion.Body>
                            <Row className="mb-1">
                                <Form.Group as={Col} md="12" controlId="formBasicName">
                                    <Form.Label className="label">Address</Form.Label>
                                    <Form.Control as="textarea" value={data.shippingAddress} rows={3} name='address' placeholder='Enter Shipping Address' />
                                </Form.Group>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header>Details</Accordion.Header>
                        <Accordion.Body>
                            <Row className="mb-1">
                                <Form.Group as={Col} md="12" controlId="formBasicName">
                                    <Form.Label className="label">Remarks</Form.Label>
                                    <Form.Control as="textarea" value={data.remarks} rows={3} name='address' placeholder='Enter Remarks' />
                                </Form.Group>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>

                <br></br>
                <Table striped responsive hover size="sm">
                    <thead className='p-3 mb-2 bg-success text-white'>
                        <tr>
                            <th style={{ width: '20%' }}>PRODUCT&nbsp;NAME</th>
                            <th style={{ width: '9%' }}>HSN&nbsp;Code</th>
                            <th style={{ width: '8%' }}>GST</th>
                            <th style={{ width: '8%' }}>UOM</th>
 
                            <th style={{ width: '8%' }}>QTY(PCS)</th>
                            <th style={{ width: '8%' }}>QTY(KG)</th>
                            <th style={{ width: '8%' }}>RQTY-(PCS)</th>

                            {/* remainingpcsqty */}
                            <th style={{ width: '8%' }}>RATE (₹)</th>
                            <th style={{ width: '8%' }}>UOM</th>
                            <th style={{ width: '8%' }}>GROSS AMOUNT(₹)</th>
                            <th style={{ width: '8%' }}>DISCOUNT %</th>
                            <th style={{ width: '8%' }}>NET AMOUNT (₹)</th>
                            {/* <th>Per&nbsp;Unit&nbsp;Rate</th> */}
                            <th style={{ width: '8%' }}>GST VALUE&nbsp;(₹)</th>
                            <th style={{ width: '8%' }}>TOTAL&nbsp;(₹)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                            {/* <th>View</th> */}
                            {/* <th>Action&nbsp;&nbsp;</th> */}
                        </tr>
                    </thead>
                    <tbody style={{ overflow: "scroll" }}>
                        {(purchaseItems).map(item =>
                            <tr key={item.id}>
                                <td>
                                    <Form.Group as={Col} md="12" >
                                        {/* <Form.Control type="text" value={(item.product.productName)} size="sm" /> */}
                                        <textarea type="text" className="form-control" value={(item.product.productName)} size="sm" ></textarea>

                                    </Form.Group>
                                    <Form.Control as="textarea" value={item.text} rows={2} className='form-control' />
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="text" size="sm" value={item.hsncode || ""} />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="text" size="sm" value={item.igst || ""} />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="text" size="sm" value={item.unitofmeasurement || ""} />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="text" size="sm" value={item.poquantity || ""} />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="text" size="sm" value={item.poquantitykgs || ""} />
                                    </Form.Group>
                                </td>
                                
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="text" size="sm" value={item.remainingpcsqty || ""} />
                                    </Form.Group>
                                </td>

                                {/* remainingpcsqty */}
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="text" size="sm" value={item.dlp || ""} />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="text" size="sm" value={item.calunitofmeasurement || ""} />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="text" size="sm" value={item.grossamount || ""} />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="text" value={item.tradeDiscount || ''} size="sm" />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="text" value={Number(item.total)} size="sm" />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="text" value={Number(item.gstvalue)} size="sm" />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="text" value={Number(item.amount)} size="sm" />
                                    </Form.Group>
                                </td>
                                {/* <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type='button' className='btn btn-danger' value="X" size="sm" />
                                    </Form.Group>
                                </td> */}
                            </tr>
                        )}
                    </tbody>
                    <tbody>

                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>Packing&nbsp;&&nbsp;Forwarding</td>
                            <td> <Form.Group as={Col} md="12">
                                <Form.Control type="number" value={data.shippingcharge} style={{ height: 30 }} />
                            </Form.Group></td>
                            <td></td>
                        </tr>


                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>Net&nbsp;Amount</td>
                            <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="number" value={data.netAmount} style={{ height: 30 }} readOnly />
                                </Form.Group>
                            </td>
                            <td></td>
                        </tr>

                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>IGST</td>
                            <td><Form.Group as={Col} md="12">
                                <Form.Control type="number" value={data.igst} requied style={{ height: 30 }} />
                            </Form.Group></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>SGST</td>
                            <td> <Form.Group as={Col} md="12">
                                <Form.Control type="number" value={data.sgst} requied style={{ height: 30 }} />
                            </Form.Group></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>CGST</td>
                            <td><Form.Group as={Col} md="12">
                                <Form.Control type="number" value={data.cgst} requied style={{ height: 30 }} />
                            </Form.Group></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>Round&nbsp;off&nbsp;Value</td>
                            <td><Form.Group as={Col} md="12">
                                <Form.Control type="number" value={data.roundingofvalue} style={{ height: 30 }} />
                            </Form.Group></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>Grand&nbsp;Total</td>
                            <td><Form.Group as={Col} md="12">
                                <Form.Control type="number" value={data.grandtotal} readOnly style={{ height: 30 }} />
                            </Form.Group></td>
                            <td></td>
                        </tr>
                    </tbody>
                </Table>

            </Form>
        </MDBCard>
    )
}