import React from 'react'
import { Mydatatable } from '../Master/Product/Mydatatable'

export const Newpurchase = () => {





    let header = [
        { "label": "Id", "field": "id" },
        { "label": "Purchase Date", "field": "purchasedate" },
        { "label": "  Company Name", "field": "companyname" },
        
// 
// 
        { "label": "Invoice No", "field": "invoiceno" },
        { "label": "E-way bill no", "field": "ewaybillno" },

        // invoiceno
        { "label": "Contact Name", "field": "contactname" },
        { "label": "City", "field": "cities" },
        { "label": "Shipping Address", "field":"shippingaddress" },
     { "label": "Taxable ", "field": "netAmount" },
     { "label": "Tax", "field": "igst" },
     { "label": "Grand total", "field": "grandtotal" },
    //  grandtotal
    { "label": "Created Date", "field": "createddate" },
    { "label": "Created Time", "field": "createdtime" },
    { "label": "Created By", "field": "createbyname" },
    { "label": "Updated Date", "field": "updateddate" },
    { "label": "Updated Time", "field": "updatedtime" },
    { "label": "Updated By", "field": "updatedbyname" },
        { "label": "Action", "field": "action","subaction1":<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"/><circle cx="12" cy="12" r="3"/></svg> }
      ]; 



      const sumofkeys=[{"first":'igst'} ]


  return (
    <Mydatatable
    head={header}
    title={"Purchase"}
    axiostabledata={'purchase'}
    Add={"Add Purchase"}
    Create={'/PurchaseCreate12'}
    Edit={"/PurchaseView"}
    View={"/PurchaseView"}
    Sumofkeys={sumofkeys}
    />
  )
}
