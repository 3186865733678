// import axios from "axios";
// import { MDBCard } from "mdbreact";
// import React, { useEffect, useState } from "react";
// import { Button, Col, Form, Row } from "react-bootstrap";
// import { useNavigate, useParams } from "react-router-dom";
// import { BASE_URL } from "../../services/Api";
// import authHeader from "../../services/auth-header";
// import Swal from "sweetalert2";
// import Select from "react-select";

// export default function Update(){
//     const {id} = useParams();
// const[assetsname,setassetsname] = useState('');
// const[mrp,setmrp] = useState('');
// const[srno,setsrno] = useState('');
// const[remarks,setremarks] = useState('');
// const[status,setstatus] = useState('');
// const[expiryDate,setexpiryDate] = useState('');
// const navigate = useNavigate();

// const [isLoading, setIsLoading] = useState(false);

// //////////
// const [invoiceNo, setInvoiceNo] = useState('');
// const [invoiceDate, setInvoiceDate] = useState('');
// const [insuranceProviderName, setInsuranceProviderName] = useState('');
// const [insurancePolicyNo, setInsurancePolicyNo] = useState('');
// const [ipnStartDate, setIpnStartDate] = useState('');
// const [ipnEndDate, setIpnEndDate] = useState('');
// const [insuranceFileName, setInsuranceFileName] = useState('');
// const [insuranceFileLocation, setInsuranceFileLocation] = useState('');
// const [supplier, setSupplier] = useState('');
// const [inscFile, setInscFile] = useState(null);
// const [inscFile2, setInscFile2] = useState(null);

// const applyFontSizeTransition = (elementId) => {
//   const element = document.getElementById(elementId);
//   element.style.transition = 'font-size 2s';
//   element.style.fontSize = '30px';
//   element.style.fontWeight = '600';
//   // font-weight: 600;
//   setTimeout(() => {
     
//     document.getElementById("typer1").classList.add('promote');
//     element.style.fontSize = '24px';
//   }, 1000);
// };
// let Toast = Swal.mixin({
//   toast: true,
//   position: 'top-end',
//   showConfirmButton: false,
//   timer: 3000,
//   timerProgressBar: true,
//   didOpen: (toast) => {
//     toast.addEventListener('mouseenter', Swal.stopTimer)
//     toast.addEventListener('mouseleave', Swal.resumeTimer)
//   }
// });
// // formData.append('assetsname', assetsname);
// // formData.append('mrp', mrp);
// // formData.append('srno', srno);
// // formData.append('remarks', remarks);
// // formData.append('expiryDate', expiryDate);
// // formData.append('invoiceno', invoiceNo);
// // formData.append('invoicedate', invoiceDate);
// // formData.append('insuranceprovidername', insuranceProviderName);
// // formData.append('insurancepolicyno', insurancePolicyNo);
// // formData.append('ipnstartdate', ipnStartDate);
// // formData.append('ipnenddate', ipnEndDate);
// useEffect(()=>{
//     axios.get(BASE_URL+'asset/byid/'+id,{
//         headers:authHeader()
//     }).then(res=>{
//         setassetsname(res.data.assetsname);
//         setmrp(res.data.mrp);
//         setsrno(res.data.srno);
//         setremarks(res.data.remarks);
//         setstatus(res.data.status);
//         setexpiryDate(res.data.expiryDate);
//         setInvoiceNo(res.data.invoiceno);
//         setInvoiceDate(res.data.invoicedate)
//         setInsuranceProviderName(res.data.insuranceprovidername);
//         setInsurancePolicyNo(res.data.insurancepolicyno);
//         setIpnStartDate(res.data.ipnstartdate);
//         setIpnEndDate(res.data.ipnenddate);
//         setInscFile2(res.data.insurancefilelocation)
//         setSupplier([res.data.supplier].map(use => ({ value: use.id, label: use.companyname }))[0]);

//     })
// },[]);
// const [disword, setdisword] = useState(encodeURIComponent(' '))
// const [dis, setdistributordata1] = useState([])
// const onChangeSupplier = (e) => {
//   // let name=
//   setSupplier( [ e][0] );
 
// };


// useEffect(() => {

  // axios.get(`http://43.225.54.138:8080/scm/api/supplier/page/0/10/asc/id/${disword.length ? disword : encodeURIComponent(' ')}`, { headers: authHeader() })
  // .then(response => {
  //     console.log(response)
  //     // if (response.data.data == undefined) {
  //     //     setdistributordata1(response.data.Index.map(use => ({ value: use.id, label: use.companyname })));
  //     // } if (response.data.Index == undefined) {
  //     //     setdistributordata1(response.data.data.map(use => ({ value: use.id, label: use.companyname })));

  //     // }

  //     setdistributordata1(response.data.Index !== undefined ? response.data.Index.map(use => ({ value: use.id, label: use.companyname })) : response.data.data.map(use => ({ value: use.id, label: use.companyname })))

      //   console.log(response.data.data.map(use => ({ value: use.id, label: use.suppliername})))  
  //})

    

// }, [disword])
// console.log(supplier)
// const downloadLink = `http://43.225.54.138:8080/scm/${inscFile}`;

// const defaultvalue = new Date(expiryDate);
// const default_Value = defaultvalue.toLocaleDateString('en-CA');

// const submitdata = () =>{
//   const emptyBlob = new Blob([""], { type: "application/octet-stream" });

//     setIsLoading(true);
//      // Create a new FormData instance
//      const formData = new FormData();
  
//      // Append each form field to the FormData object
//      formData.append('assetsname', assetsname);
//      formData.append('mrp', mrp);
//      formData.append('srno', srno);
//      formData.append('remarks', remarks);
//      formData.append('expiryDate', expiryDate);
//      formData.append('invoiceno', invoiceNo);
//      formData.append('invoicedate', invoiceDate);
//      formData.append('insuranceprovidername', insuranceProviderName);
//      formData.append('insurancepolicyno', insurancePolicyNo);
//      formData.append('ipnstartdate', ipnStartDate);
//      formData.append('ipnenddate', ipnEndDate);
//       // inscfile
//      formData.append('supplier', supplier.value);
     
//      // Append the file separately if it exists
//     //  if (inscFile) {
// {      inscFile? formData.append('inscfile', inscFile):formData.append('inscfile', emptyBlob, 'empty-file.txt');
// }    //  }
//     axios.put(BASE_URL + 'asset/update/'+id,formData,{
//         headers:authHeader()
//     }).then(res=>{
//         navigate('/Assest/Table/1');
//         if(res.status==201||res.status==200){
//             // alert("ee")
//             Toast.fire({
//               icon: 'success',
//               title: 'Successfully Updated'
//             })
//           }
//     }).catch(error => {
//         console.error('Error adding  :', error);
//         if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
//         Swal.fire({
//           icon: "error",
//           title: "Oops...",
//           text: "Something went wrong!",
//           footer: `
//             <div>
//               <p id="issueText">Why do I have this issue?</p>
//               <ul>
          
//               <li id="typer1">You might have an internet issue</li>
//               </ul>
//             </div>
//           `,
//           didOpen: () => {
//             applyFontSizeTransition('issueText');
//           }
//         });
//       }
//       })
//       .finally(() => {
//         setIsLoading(false); 
//       });
// };
// // console.log(supplier[0].value)
//     return(
//         <MDBCard className="company">
//             <h1>Update Asset</h1>
//             <Form className="product1">
//                 <Row className="mb-1">
//                 <Form.Group as={Col} md="6" controlId="formBasicName">
//                         <Form.Label className="label">Asset Name</Form.Label>
//                         <Form.Control type="text" name="assestname" value={assetsname} onChange={(e)=>setassetsname(e.target.value)} autoComplete="off" placeholder="Enter Assest Name"/>
//                     </Form.Group>
//                     <Form.Group as={Col} md="6" controlId="formBasicName">
//                         <Form.Label className="label">MRP</Form.Label>
//                         <Form.Control type="text" name="mrp" value={mrp} onChange={(e)=>setmrp(e.target.value)} autoComplete="off" placeholder="Enter MRP"/>
//                     </Form.Group>
//                     <Form.Group as={Col} md="6" controlId="formBasicName">
//                         <Form.Label className="label">Warranty Till Date</Form.Label>
//                         <Form.Control type="date" value={expiryDate} name="expiryDate" onChange={(e)=>setexpiryDate(e.target.value)}/>
//                     </Form.Group>
//                     <Form.Group as={Col} md="6" controlId="formBasicName">
//                         <Form.Label className="label">Serial Number</Form.Label>
//                         <Form.Control type="text" name="srno" value={srno} onChange={(e)=>setsrno(e.target.value)} autoComplete="off" placeholder="Enter Serial Number"/>
//                     </Form.Group>
//                     <Form.Group as={Col} md="12" controlId="formBasicName">
//                         <Form.Label className="label">Remarks</Form.Label>
//                         <Form.Control as="textarea" rows={3} value={remarks} name='remarks' onChange={(e)=>setremarks(e.target.value)} placeholder='Enter Remarks' autoComplete="off" />
//                     </Form.Group>
//                     <Form.Group as={Col} md="6" controlId="formBasicName">
//                         <Form.Label className="label">Invoice Number</Form.Label>
//                         <Form.Control type="text" name="invoiceNo" value={invoiceNo} onChange={(e)=>setInvoiceNo(e.target.value)} autoComplete="off" placeholder="Enter MRP"/>
//                     </Form.Group>
//                     <Form.Group as={Col} md="6" controlId="formBasicName">
//                         <Form.Label className="label">Invoice Date</Form.Label>
//                         <Form.Control type="date" name="invoiceNo" value={invoiceDate} onChange={(e)=>setInvoiceDate(e.target.value)} autoComplete="off" placeholder="Enter MRP"/>
//                     </Form.Group>
//                     <Form.Group as={Col} md="6" controlId="formBasicName">
//                         <Form.Label className="label">Insurance Provider Name</Form.Label>
//                         <Form.Control type="text" name="invoiceNo" value={insuranceProviderName} onChange={(e)=>setInsuranceProviderName(e.target.value)} autoComplete="off" placeholder="Insurance Policy "/>
//                     </Form.Group>
//                     <Form.Group as={Col} md="6" controlId="formBasicName">
//                         <Form.Label className="label">Insurance Policy Number</Form.Label>
//                         <Form.Control type="text" name="Insurance Policy Number" value={insuranceProviderName} onChange={(e)=>setInsuranceProviderName(e.target.value)} autoComplete="off" placeholder="Insurance Policy Number"/>
//                     </Form.Group>

//                     <Form.Group as={Col} md="6" controlId="formIpnStartDate">
//             <Form.Label className="label">IPN Start Date</Form.Label>
//             <Form.Control type="date" value={ipnStartDate} name="ipnStartDate" onChange={(e) => setIpnStartDate(e.target.value)} autoComplete="off" />
//           </Form.Group>
//           <Form.Group as={Col} md="6" controlId="formIpnEndDate">
//             <Form.Label className="label">IPN End Date</Form.Label>
//             <Form.Control type="date" value={ipnEndDate}  name="ipnEndDate" onChange={(e) => setIpnEndDate(e.target.value)} autoComplete="off" />
//           </Form.Group>
//           <Form.Group as={Col} md="6" controlId="formSupplier">
//             <Form.Label className="label">Supplier</Form.Label>
            
//             <Select
//   options={dis} 
//   value={[{ value: [supplier].map((item)=>item.value) , label: [supplier].map((item)=>item.label) }]} 
//   onInputChange={(inputValue) => setdisword(inputValue)} 
//   onChange={(options) => onChangeSupplier(options)}
// />

//             {/* <Form.Control type="text" name="supplier" onChange={(e) => setSupplier(e.target.value)} autoComplete="off" placeholder="Enter Supplier" /> */}
//           </Form.Group>
//           <Form.Group as={Col} md="6" controlId="formIpnEndDate">
//             <Form.Label className="label">Insurance File</Form.Label>
//             <a 
//   href={`http://43.225.54.138:8080/scm/${inscFile2}`} 
//   target="_blank" 
//   rel="noopener noreferrer" 
//   style={{
//     display: 'inline-block',
//     padding: '10px 20px',
//     fontSize: '16px',
//     fontWeight: 'bold',
//     color: '#fff',
//     backgroundColor: '#007bff',
//     border: 'none',
//     borderRadius: '5px',
//     textAlign: 'center',
//     textDecoration: 'none',
//     cursor: 'pointer'
//   }}
// >
//   Open File
// </a>
// <Form.Control type="file" name="inscFile" onChange={(e) => setInscFile(e.target.files[0])} autoComplete="off" />

//             {/* <Form.Control type="date" value={ipnEndDate}  name="ipnEndDate" onChange={(e) => setIpnEndDate(e.target.value)} autoComplete="off" /> */}
//           </Form.Group>
//                 </Row>
//                 </Form>
//                 {/* <Button variant="primary" onClick={submitdata} type="button" >
//                     Submit
//                 </Button> */}
//                 <Button variant="primary"   onClick={submitdata} disabled={isLoading}>
//           {isLoading ? 'Loading...' : 'Submit'}
//         </Button>
//         </MDBCard>
//     )
// }

import axios from "axios";
import { MDBCard } from "mdbreact";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../../services/Api";
import authHeader from "../../services/auth-header";
import Swal from "sweetalert2";
import Select from "react-select";

export default function Update(){
    const {id} = useParams();
const[assetsname,setassetsname] = useState('');
const[mrp,setmrp] = useState('');
const[srno,setsrno] = useState('');
const[remarks,setremarks] = useState('');
const[status,setstatus] = useState('');
const[expiryDate,setexpiryDate] = useState('');
const navigate = useNavigate();

const [isLoading, setIsLoading] = useState(false);

//////////
const [invoiceNo, setInvoiceNo] = useState('');
const [invoiceDate, setInvoiceDate] = useState('');
const [insuranceProviderName, setInsuranceProviderName] = useState('');
const [insurancePolicyNo, setInsurancePolicyNo] = useState('');
const [ipnStartDate, setIpnStartDate] = useState('');
const [ipnEndDate, setIpnEndDate] = useState('');
const [insuranceFileName, setInsuranceFileName] = useState('');
const [insuranceFileLocation, setInsuranceFileLocation] = useState('');
const [supplier, setSupplier] = useState('');
const [inscFile, setInscFile] = useState(null);
const [inscFile2, setInscFile2] = useState(null);
const [supplierValue,setSupplierValue] = useState([]);
const [supplierSearch,setSupplierSearch] = useState("");

const applyFontSizeTransition = (elementId) => {
  const element = document.getElementById(elementId);
  element.style.transition = 'font-size 2s';
  element.style.fontSize = '30px';
  element.style.fontWeight = '600';
  // font-weight: 600;
  setTimeout(() => {
     
    document.getElementById("typer1").classList.add('promote');
    element.style.fontSize = '24px';
  }, 1000);
};
let Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
});
// formData.append('assetsname', assetsname);
// formData.append('mrp', mrp);
// formData.append('srno', srno);
// formData.append('remarks', remarks);
// formData.append('expiryDate', expiryDate);
// formData.append('invoiceno', invoiceNo);
// formData.append('invoicedate', invoiceDate);
// formData.append('insuranceprovidername', insuranceProviderName);
// formData.append('insurancepolicyno', insurancePolicyNo);
// formData.append('ipnstartdate', ipnStartDate);
// formData.append('ipnenddate', ipnEndDate);
useEffect(()=>{
    axios.get(BASE_URL+'asset/byid/'+id,{
        headers:authHeader()
    }).then(res=>{
      console.log(res);
      
        setassetsname(res.data.assetsname);
        setmrp(res.data.mrp);
        setsrno(res.data.srno);
        setremarks(res.data.remarks);
        setstatus(res.data.status);
        setexpiryDate(res.data.expiryDate);
        setInvoiceNo(res.data.invoiceno);
        setInvoiceDate(res.data.invoicedate)
        setInsuranceProviderName(res.data.insuranceprovidername);
        setInsurancePolicyNo(res.data.insurancepolicyno);
        setIpnStartDate(res.data.ipnstartdate);
        setIpnEndDate(res.data.ipnenddate);
        setInscFile2(res.data.insurancefilelocation)
        setSupplier([res.data.supplier].map(use => ({ value: use.id, label: use.companyname }))[0]);
        setSupplierValue({label:res.data.supplier.companyname,value:res.data.supplier.id})    

    })
},[]);
const [disword, setdisword] = useState(encodeURIComponent(' '))
const [dis, setdistributordata1] = useState([]);
const onChangeSupplier = (e) => {
  // let name=
  setSupplier( [ e][0] );
 
};

async function getSupplierData() {
  try {
    const response = await axios.get(`http://43.225.54.138:8080/scm/api/supplier/page/0/10/asc/id/${disword?.length > 0 ? disword : encodeURIComponent(' ')}`, { headers: authHeader() })
  
      console.log(response.data);
      

      setdistributordata1(response.data?.Index?.length > 0  ? response.data.Index.map(use => ({ value: use.id, label: use.companyname })) : response.data.data.map(use => ({ value: use.id, label: use.companyname })))

      //   console.log(response.data.data.map(use => ({ value: use.id, label: use.suppliername})))  
  
  } catch (error) {
    console.log(error);
    
  }
}
useEffect(() => {

   getSupplierData();

    /** Voucher GetAll */

    

}, [disword])
console.log(supplier)
const downloadLink = `http://43.225.54.138:8080/scm/${inscFile}`;

const defaultvalue = new Date(expiryDate);
const default_Value = defaultvalue.toLocaleDateString('en-CA');




const submitdata = () =>{
  const emptyBlob = new Blob([""], { type: "application/octet-stream" });

    setIsLoading(true);
     // Create a new FormData instance
     const formData = new FormData();
  
     // Append each form field to the FormData object
     formData.append('assetsname', assetsname);
     formData.append('mrp', mrp);
     formData.append('srno', srno);
     formData.append('remarks', remarks);
     formData.append('expiryDate', expiryDate);
     formData.append('invoiceno', invoiceNo);
     formData.append('invoicedate', invoiceDate);
     formData.append('insuranceprovidername', insuranceProviderName);
     formData.append('insurancepolicyno', insurancePolicyNo);
     formData.append('ipnstartdate', ipnStartDate);
     formData.append('ipnenddate', ipnEndDate);
      // inscfile
     formData.append('supplier', supplier.value);
     
     // Append the file separately if it exists
    //  if (inscFile) {
{      inscFile? formData.append('inscfile', inscFile):formData.append('inscfile', emptyBlob, 'empty-file.txt');
}    //  }
    axios.put(BASE_URL + 'asset/update/'+id,formData,{
        headers:authHeader()
    }).then(res=>{
        navigate('/Assest/Table/1');
        if(res.status==201||res.status==200){
            // alert("ee")
            Toast.fire({
              icon: 'success',
              title: 'Successfully Updated'
            })
          }
    }).catch(error => {
        console.error('Error adding  :', error);
        if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `
            <div>
              <p id="issueText">Why do I have this issue?</p>
              <ul>
          
              <li id="typer1">You might have an internet issue</li>
              </ul>
            </div>
          `,
          didOpen: () => {
            applyFontSizeTransition('issueText');
          }
        });
      }
      })
      .finally(() => {
        setIsLoading(false); 
      });
};
// console.log(supplier[0].value)

function supplierChangeHandler(e) {
  setSupplierValue(e)
}

const normalizeString = (str) => str.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();

const customFilterOption = (option, inputValue) => {
  const normalizedOptionLabel = normalizeString(option.label);
  const normalizedInputValue = normalizeString(inputValue);
  return normalizedOptionLabel.includes(normalizedInputValue);
};

console.log(dis);
console.log(supplierValue);

    return(
        <MDBCard className="company">
            <h1>Update Asset</h1>
            <Form className="product1">
                <Row className="mb-1">
                <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Asset Name</Form.Label>
                        <Form.Control type="text" name="assestname" value={assetsname} onChange={(e)=>setassetsname(e.target.value)} autoComplete="off" placeholder="Enter Assest Name"/>
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">MRP</Form.Label>
                        <Form.Control type="text" name="mrp" value={mrp} onChange={(e)=>setmrp(e.target.value)} autoComplete="off" placeholder="Enter MRP"/>
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Warranty Till Date</Form.Label>
                        <Form.Control type="date" value={expiryDate} name="expiryDate" onChange={(e)=>setexpiryDate(e.target.value)}/>
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Serial Number</Form.Label>
                        <Form.Control type="text" name="srno" value={srno} onChange={(e)=>setsrno(e.target.value)} autoComplete="off" placeholder="Enter Serial Number"/>
                    </Form.Group>
                    <Form.Group as={Col} md="12" controlId="formBasicName">
                        <Form.Label className="label">Remarks</Form.Label>
                        <Form.Control as="textarea" rows={3} value={remarks} name='remarks' onChange={(e)=>setremarks(e.target.value)} placeholder='Enter Remarks' autoComplete="off" />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Invoice Number</Form.Label>
                        <Form.Control type="text" name="invoiceNo" value={invoiceNo} onChange={(e)=>setInvoiceNo(e.target.value)} autoComplete="off" placeholder="Enter MRP"/>
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Invoice Date</Form.Label>
                        <Form.Control type="date" name="invoiceNo" value={invoiceDate} onChange={(e)=>setInvoiceDate(e.target.value)} autoComplete="off" placeholder="Enter MRP"/>
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Insurance Provider Name</Form.Label>
                        <Form.Control type="text" name="invoiceNo" value={insuranceProviderName} onChange={(e)=>setInsuranceProviderName(e.target.value)} autoComplete="off" placeholder="Insurance Policy "/>
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Insurance Policy Number</Form.Label>
                        <Form.Control type="text" name="Insurance Policy Number" value={insuranceProviderName} onChange={(e)=>setInsuranceProviderName(e.target.value)} autoComplete="off" placeholder="Insurance Policy Number"/>
                    </Form.Group>

                    <Form.Group as={Col} md="6" controlId="formIpnStartDate">
            <Form.Label className="label">IPN Start Date</Form.Label>
            <Form.Control type="date" value={ipnStartDate} name="ipnStartDate" onChange={(e) => setIpnStartDate(e.target.value)} autoComplete="off" />
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formIpnEndDate">
            <Form.Label className="label">IPN End Date</Form.Label>
            <Form.Control type="date" value={ipnEndDate}  name="ipnEndDate" onChange={(e) => setIpnEndDate(e.target.value)} autoComplete="off" />
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formSupplier">
            <Form.Label className="label">Supplier</Form.Label>
            
            {/* <Select
              options={dis} 
              value={[{ value: [supplier].map((item)=>item.value) , label: [supplier].map((item)=>item.label) }]} 
              onInputChange={(inputValue) => setdisword(inputValue)} 
              onChange={(options) => onChangeSupplier(options)}
            /> */}

            <Select 
              value={supplierValue}
              onChange={supplierChangeHandler}
              options={dis}
              filterOption={customFilterOption}
              onInputChange={(e) => setdisword(e)}
            />

            {/* <Form.Control type="text" name="supplier" onChange={(e) => setSupplier(e.target.value)} autoComplete="off" placeholder="Enter Supplier" /> */}
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formIpnEndDate">
            <Form.Label className="label">Insurance File</Form.Label>
            <a 
  href={`http://43.225.54.138:8080/scm/${inscFile2}`} 
  target="_blank" 
  rel="noopener noreferrer" 
  style={{
    display: 'inline-block',
    padding: '10px 20px',
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#fff',
    backgroundColor: '#007bff',
    border: 'none',
    borderRadius: '5px',
    textAlign: 'center',
    textDecoration: 'none',
    cursor: 'pointer'
  }}
>
  Open File
</a>
<Form.Control type="file" name="inscFile" onChange={(e) => setInscFile(e.target.files[0])} autoComplete="off" />

            {/* <Form.Control type="date" value={ipnEndDate}  name="ipnEndDate" onChange={(e) => setIpnEndDate(e.target.value)} autoComplete="off" /> */}
          </Form.Group>
                </Row>
                </Form>
                {/* <Button variant="primary" onClick={submitdata} type="button" >
                    Submit
                </Button> */}
                <Button variant="primary"   onClick={submitdata} disabled={isLoading}>
          {isLoading ? 'Loading...' : 'Submit'}
        </Button>
        </MDBCard>
    )
}