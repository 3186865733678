import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom'
import StaffService from '../../services/StaffService';
import { MDBCard } from 'mdbreact';
import { Container, Form, Row, Button, Col } from 'react-bootstrap';
import Select from 'react-select';
import UserService from '../../services/user.service';
import Swal from 'sweetalert2';
import axios from 'axios';
import { BASE_URL } from '../../services/Api';
import authHeader from '../../services/auth-header';
import AuthService from '../../services/auth.service';
export default function SEdit() {
  const [isLoading, setIsLoading] = useState(false);
  const applyFontSizeTransition = (elementId) => {
    const element = document.getElementById(elementId);
    element.style.transition = 'font-size 2s';
    element.style.fontSize = '30px';
    element.style.fontWeight = '600';
    // font-weight: 600;
    setTimeout(() => {
       
      document.getElementById("typer1").classList.add('promote');
      element.style.fontSize = '24px';
    }, 1000);
  };
  const [staffName, setstaffName] = useState('');
  const [doj, setdoj] = useState('');
  const [address, setaddress] = useState('');
  const [mobileNumber, setmobileNumber] = useState('');
  const [email, setemail] = useState('');
  const [gender, setgender] = useState('');
  const [designation, setdesignation] = useState('');
  const [salary, setsalary] = useState('');
  const [area, setarea] = useState('');
  const [dateOfBirth, setdateOfBirth] = useState('');
  const [bloodGroup, setbloodGroup] = useState('');
  const [fatherName, setfatherName] = useState('');
  const [spouseName, setspouseName] = useState('');
  const [bankDetail, setbankDetail] = useState('');
  const [accountNumber, setaccountNumber] = useState('');
  const [ifscCode, setifscCode] = useState('');
  // 
  const [bankName, setbankName] = useState('');
  const [branchName, setbranchName] = useState('');
  const [panNumber, setpanNumber] = useState('');
  const [aadharNumber, setaadharNumber] = useState('');
  const [dateOfAnniversary, setdateOfAnniversary] = useState('');
  const [password, setpassword] = useState('');
  const [nsm, setnsm] = useState([]);
  const [nsmId, setnsmId] = useState(0);
  const [zonesdata, setzonesdata] = useState([]);
  const [zonesAll, setzonesAll] = useState([]);
  const [zoneId, setzoneId] = useState('');
  // const [statedata, setStatedata] = useState([]);
  const [statevalue, setStatevalue] = useState([]);
  // const [stateZoneId, setstateZoneId] = useState('');
  const [rsm, setRsm] = useState([]);
  const [rsmvalue, setRsmvalue] = useState([]);
  const [rsmId, setrsmId] = useState(0);
  const [asm, setasm] = useState([]);
  const [statedata, setStatedata] = useState([]);
  const [statedatavalue, setStatedatavalue] = useState([]);
  const [stateZoneId, setstateZoneId] = useState('');
  const [asmvalue, setasmvalue] = useState([]);
  const [asmId, setasmId] = useState(0);
  const [ase, setase] = useState([]);
  const [asevalue, setasevalue] = useState([]);
  const [aseId, setaseId] = useState(0);
  const [RolesId, setRolesId] = useState([]);
  const [RolesValue, setRolesValue] = useState([]);
  const [RolesData, setRolesData] = useState([]);
  const [roles_id, setroles_id] = useState('');
  const [error, setError] = useState(false);
  const[emailLoginStatus,setemailLoginStatus] = useState('');
  const[updatestatus,setupdatestatus] = useState(false);
  const [verifydone, setverifydone] = useState('');
  const [opttimeout, setopttimeout] = useState('');
  const [status, setstatus] = useState('');
  const [sendotp, setsendotp] = useState('');
  /* Staff Id */
  const { id } = useParams();
  const { pointindex } = useParams();
  const user = AuthService.getCurrentUser();
  /* Navigator */
  const navigate = useNavigate();
const [zonevalue, setzonevalue] = useState([])

  useEffect(() => {
    UserService.getNSMData().then(res => {
      console.log(res.data)
      setnsm((res.data).map(user => ({ value: user.id, label: user.staffName })))

          });
    /* Staff Data */
    StaffService.getStaffEdit(id).then((response) => {

      axios.get(`http://43.225.54.138:8080/scm/api/staff/multiple/${id}`, { headers: authHeader() })
      .then(response1 => {
  setasevalue(response1.data.filter(item => item.aseid !== 0).map(user => ({ value: user.aseid, label: user.asestaffname})))
  setasmvalue(response1.data.filter(item => item.asmid !== 0).map(user => ({ value: user.asmid, label: user.asmstaffname})))
  setRsmvalue(response1.data.filter(item => item.rsmid !== 0).map(user => ({ value: user.rsmid, label: user.rsmstaffname})))
  onRsm(response1.data.filter(item => item.rsmid !== 0).map(user => ({ value: user.rsmid, label: user.rsmstaffname})))
  onAsm(response1.data.filter(item => item.asmid !== 0).map(user => ({ value: user.asmid, label: user.asmstaffname})))
  onAse(response1.data.filter(item => item.aseid !== 0).map(user => ({ value: user.aseid, label: user.asestaffname})))
   setnsmId(response1.data.filter(item => item.nsmid !== 0).map(user => ({ value: user.nsmid, label: user.nsmstaffname})) )
  console.log(response1.data.filter(item => item.nsmid !== 0).map(user => ({ value: user.nsmid, label: user.nsmstaffname})))



  StaffService.getStaffEdit(response.data.rsmId)
  .then((response) => {
    const filteredAsms = response1.data
    ? response1.data.filter(item => item.rsmid !== 0).map(user => ({ value: user.rsmid, label: user.rsmstaffname}))
    : [];
  
  if (filteredAsms.length > 0) {
    onRsm(...filteredAsms, response.data.id, response.data.staffName);
    setRsmvalue(...filteredAsms, response.data.id, response.data.staffName);
    console.log("first")
  }else{
    console.log("second")
    setRsmvalue( [{value: response.data.id, label:response.data.staffName}]);
    onRsm( [{ value:response.data.id, label:response.data.staffName}]);

  }     
   })
  .catch((error) => {
     console.error('Error fetching staff data:', error);
  })
  .finally(() => {
     console.log('Fetch staff edit process completed.');
    

 
  });
 StaffService.getStaffEdit(response.data.asmId)
     .then((response) => {
       const filteredAsms = response1.data
       ? response1.data.filter(item => item.asmid !== 0).map(user => ({ value: user.asmid, label: user.asmstaffname }))
       : [];
     
     if (filteredAsms.length > 0) {
       onAsm(...filteredAsms, response.data.id, response.data.staffName);
       setasmvalue(...filteredAsms, response.data.id, response.data.staffName);
       console.log("first")
     }else{
       console.log("second")
       setasmvalue( [{value: response.data.id, label:response.data.staffName}]);
       onAsm( [{ value:response.data.id, label:response.data.staffName}]);
   
     }     
   
   
   
   
   
      })
     .catch((error) => {
        console.error('Error fetching staff data:', error);
     })
     .finally(() => {
        console.log('Fetch staff edit process completed.');
     });

  StaffService.getStaffEdit(response.data.nsmId)
  .then((response) => {
    const filteredAsms = response1.data
    ? response1.data.filter(item => item.nsmid !== 0).map(user => ({ value: user.nsmid, label: user.nsmstaffname}))
    : [];
  
  if (filteredAsms.length > 0) {
   onNsm(...filteredAsms, response.data.id, response.data.staffName);
   setnsmId(...filteredAsms, response.data.id, response.data.staffName);
    console.log("first")
  }else{
    console.log("second")
    setnsmId( [{value: response.data.id, label:response.data.staffName}]);
    onNsm( [{ value:response.data.id, label:response.data.staffName}]);

  }     
   })
  .catch((error) => {
     console.error('Error fetching staff data:', error);
  })
  .finally(() => {
     console.log('Fetch staff edit process completed.');
  });
  


      })
      .catch(error => {
        console.error(error);
      });
    
      console.log('mello',response.data);
      setstaffName(response.data.staffName);
      setaddress(response.data.address);
      setstateZoneId(response.data.stateZoneId);
      setmobileNumber(response.data.mobileNumber);
      setemail(response.data.email);
      setgender(response.data.gender);
      setroles_id(response.data.roles[0].id);
      setsalary(response.data.salary);
      setarea(response.data.area);
      setbloodGroup(response.data.bloodGroup);
      setfatherName(response.data.fatherName);
      setspouseName(response.data.spouseName);
      setbankDetail(response.data.bankDetail);
      setaccountNumber(response.data.accountNumber);
      setifscCode(response.data.ifscCode);
      setbankName(response.data.bankName);
      setbranchName(response.data.branchName);
      setpanNumber(response.data.panNumber);
      setaadharNumber(response.data.aadharNumber);
      setpassword('');
      setnsmId(response.data.nsmId);
      setrsmId(response.data.rsmId);
      setasmId(response.data.asmId);
      setaseId(response.data.aseId);
      setzoneId(response.data.zones.map((item)=>({value:item.id,label:item.zoneName}))); 
      onZone12(response.data.zones.map((item)=>({value:item.id,label:item.zoneName}))); 
      onSelect12(response.data.statezones.map((item)=>({value:item.id,label:item.state_name})))
      setstateZoneId(response.data.statezones.map((item)=>({value:item.id,label:item.state_name})))
     
      setemailLoginStatus(response.data.emailLoginStatus);



      // var date = response.data.doj;
      console.log(response.data.zones.map((item)=>({value:item.id,label:item.zoneName})))
      // var newdate = date.split("-").reverse().join("-");
      setdoj(response.data.doj);
      UserService.getZoneData().then(res => {
        let zone = res.data;
        setzonesdata(zone.map(user => ({ value: user.id, label: user.zoneName })))
       });
       UserService.getZoneDataEdit(id).then(res => {
        console.log({value:res.data.id,label:res.data.zoneName})
        setzoneId(prev => [...prev, { value: res.data.id, label: res.data.zoneName }]);


        const newZones = [
          ...response.data.zones.map(item => ({ value: item.id, label: item.zoneName })),
          { value: res.data.id, label: res.data.zoneName }
        ];
        
        // Remove duplicates based on the `value` property
        const uniqueZones = Array.from(new Map(newZones.map(zone => [zone.value, zone])).values());
        
        onZone12(uniqueZones);
        console.log(uniqueZones)
        // onZone(newZones);

        let state = response.data.stateZoneId;
        ((res.data).state_zone).map(item => {
          if (item.id === state) {
             setstateZoneId(prev => [...prev, { value: item.id,  label: item.state_name }])
            
             const newState = [
              ...response.data.statezones.map((item)=>({value:item.id,label:item.state_name})),
              {value: item.id,  label: item.state_name }
            ];
            console.log(newState)
            onSelect12(newState)

             //  const
          }
        });

        // setstateZoneId(prev => [...prev, { value: res.data.id, label: res.data.state_zone.map((item)=>item.) }]);
        });
      // var date1 = response.data.dateOfBirth;
      // var newdate1 = date1.split("-").reverse().join("-");
      setdateOfBirth(response.data.dateOfBirth);

      // var date2 = response.data.dateOfAnniversary;
      // var newdate2 = date2.split("-").reverse().join("-");
      setdateOfAnniversary(response.data.dateOfAnniversary);


      /* Zone , State & RSM Data*/
   

        /* RSM */
      
       

      /* Roles */
      // StaffService.getStaffRole().then(res => {
      //   let roledata = res.data;
      //   let rolesid = response.data.roles[0].id;
      //   roledata.map(user => {
      //     if (user.id === rolesid) {
      //       setRolesValue({ value: user.id, label: user.name });
      //     }
      //   });
      //   setRolesData(res.data);
      //   setRolesId((res.data).map(user => ({ value: user.id, label: user.name })));
      // })
      StaffService.getStaffRole().then(res => {
        let roledata = res.data;
        let rolesid = response.data.roles[0].id;
        roledata.map(user => {
          if (user.id === rolesid) {
            setRolesValue({ value: user.id, label: user.name });
          }
        });
        setRolesData(res.data);
         let elemsToDelete = 3;
 
 res.data.splice(res.data.length - elemsToDelete,
    elemsToDelete);
        setRolesId((res.data).map(user => ({ value: user.id, label: user.name })));
      })
      /* NSM */

      UserService.getNSMData().then(res => {
        let nsmdata = res.data;
        let nsmdataid = response.data.nsmId;

        nsmdata.map(user => {
          if (user.id === nsmdataid) {
            // setnsm({ value: user.id, label: user.staffName })
          }
        })
      });

      /* ASM */
       


    });


  

  }, []);

  /* Role Change */
  const onRole = (e) => {
    let name = e.value;

    RolesData.map(user => {
      if (user.id === name) {
        setRolesValue({ value: user.id, label: user.name });
      }
    })
    setroles_id(e.value);
  };

  const onZone = (e) => {
    console.log(e.map((item)=>({id:item.value})))
        let name = e.value;
        setzoneId(e.map(item => ({ value: item.value, label: item.label })));
        setStatedatavalue('');
        setRsmvalue('');
        setasmvalue('');
        setasevalue('');
        /* State Data */
        setStatedata('')
    
        // http://43.225.54.138:8081/api/staff/states/zone/2,3,4
        axios.get(BASE_URL + 'staff/states/zone/' + e.map((item)=>item.value), {
          headers: authHeader()
        }).then(res => {
                  setStatedata((res.data).map(item => ({ value: item.id, label: item.statename })))
                  const validValues = stateZoneId.map(item => item.value); // Get values from e
                  console.log(validValues)
                  const filteredData = res.data.filter(item => validValues.includes(item.id)); // Filter based on valid values
                console.log(filteredData)
        setstateZoneId(filteredData.map(item => ({ value: item.id, label: item.statename })))
                }).catch(error => {
          console.error('Error fetching verification status:', error);
if(zoneId)
  {
  setstateZoneId([])

}
        });
    
        // zonesvalue.map(user => {
        //   if (user.id == name) {
        //     setStatedata((user.state_zone).map(item => ({ value: item.id, label: item.state_name })))
        //   }
        // });
    
        // /* Rsm Data */
        // UserService.getRSMData(e.value).then(res => {
        //   setRsm((res.data).map(user => ({ value: user.id, label: user.staffName })))
        // });
    
    
      };
      const onZone12 = (e) => {
        console.log(e.map((item)=>({id:item.value})))
            let name = e.value;
            setzoneId(e.map(item => ({ value: item.value, label: item.label })));
            setStatedatavalue('');
            // setRsmvalue('');
            // setasmvalue('');
            // setasevalue('');
            /* State Data */
            setStatedata('')
        
            // http://43.225.54.138:8081/api/staff/states/zone/2,3,4
            axios.get(BASE_URL + 'staff/states/zone/' + e.map((item)=>item.value), {
              headers: authHeader()
            }).then(res => {
                      setStatedata((res.data).map(item => ({ value: item.id, label: item.statename })))
                      const validValues = stateZoneId.map(item => item.value); // Get values from e
                      console.log(validValues)
                      const filteredData = res.data.filter(item => validValues.includes(item.id)); // Filter based on valid values
                    console.log(filteredData)
            setstateZoneId(filteredData.map(item => ({ value: item.id, label: item.statename })))
                    }).catch(error => {
              console.error('Error fetching verification status:', error);
    if(zoneId)
      {
      setstateZoneId([])
    
    }
            });
        
            // zonesvalue.map(user => {
            //   if (user.id == name) {
            //     setStatedata((user.state_zone).map(item => ({ value: item.id, label: item.state_name })))
            //   }
            // });
        
            // /* Rsm Data */
            // UserService.getRSMData(e.value).then(res => {
            //   setRsm((res.data).map(user => ({ value: user.id, label: user.staffName })))
            // });
        
        
          };
      const onSelect = (e) => {
        setasmvalue('');
        setasevalue('');
        setRsmvalue('');
        
        axios.get(BASE_URL + 'staff/states/zone/rsm/' + e.map((item)=>item.value), {
          headers: authHeader()
        }).then(res => {
      // Filter unique staff members based on staffid
    const uniqueStaffIds = new Set();
    const uniqueArr = res.data.filter(item => {
      if (!uniqueStaffIds.has(item.staffid)) {
        uniqueStaffIds.add(item.staffid);
        return true;  
      }
      return false;  
    });
    
    console.log(uniqueArr);
    
     setRsm(uniqueArr.map(item => ({
      value: item.staffid,
      label: item.staffname
    })));
    
          
          console.log(res.data);
        }).catch(error => {
          console.error('Error fetching verification status:', error);
        });
         setstateZoneId(e.map((item)=>({value:item.value,label:item.label})));
      };   
      const onSelect12 = (e) => {
        // setasmvalue('');
        // setasevalue('');
        // setRsmvalue('');
        
        axios.get(BASE_URL + 'staff/states/zone/rsm/' + e.map((item)=>item.value), {
          headers: authHeader()
        }).then(res => {
      // Filter unique staff members based on staffid
    const uniqueStaffIds = new Set();
    const uniqueArr = res.data.filter(item => {
      if (!uniqueStaffIds.has(item.staffid)) {
        uniqueStaffIds.add(item.staffid);
        return true;  
      }
      return false;  
    });
    
    console.log(uniqueArr);
    
     setRsm(uniqueArr.map(item => ({
      value: item.staffid,
      label: item.staffname
    })));
    
          
          console.log(res.data);
        }).catch(error => {
          console.error('Error fetching verification status:', error);
        });
         setstateZoneId(e.map((item)=>({value:item.value,label:item.label})));
      };  
  /* RSM Change */
  const onRsm = (e) => {
    console.log(e)
        let name = e.value;
        setrsmId(e.map((item)=>item.value));
        setasmvalue('');
        setasevalue('');
        setRsmvalue(e.map((item)=>({ value: item.value, label: item.label })));
        /* ASM DATA */
    
    
       
      axios.get(BASE_URL + 'staff/multiple/asm/rsm/' +e.map((item)=>item.value) , {
          headers: authHeader()
        }).then(res => {
          console.log(res.data)
          const uniqueStaffIds = new Set();
    const uniqueArr = res.data.filter(item => {
      if (!uniqueStaffIds.has(item.staffid)) {
        uniqueStaffIds.add(item.staffid);
        return true;  
      }
      return false;  
    });
    
    console.log(uniqueArr);
          setasm(uniqueArr.map(user => ({ value: user.staffid, label: user.staffname })))
          console.log(res.data);
        }).catch(error => {
          console.error('Error fetching verification status:', error);
        });
        /* ASE DATA */
    
        axios.get(BASE_URL + 'staff/multiple/ase/rsm/' + e.map((item)=>item.value), {
          headers: authHeader()
        }).then(res => {
          console.log(res.data)
          const uniqueStaffIds = new Set();
          const uniqueArr = res.data.filter(item => {
            if (!uniqueStaffIds.has(item.staffid)) {
              uniqueStaffIds.add(item.staffid);
              return true;  
            }
            return false;  
          });
          
          console.log(uniqueArr);
          setase(uniqueArr.map(user => ({ value: user.staffid, label: user.staffname })))  
              // console.log(res.data);
        }).catch(error => {
          console.error('Error fetching verification status:', error);
        });
         
    
      };


  const onNsm = (e) => {

    setnsmId(e.map((item)=>({value:item.value,label:item.label})));

  };
  /* ASM Change */

  const onAsm = (e) => {
    setasmId(e.map((item)=>item.value));
    setasevalue('');
    setasmvalue(e.map((item)=>({ value: item.value, label: item.label })));
    /* ASE DATA */
    // UserService.getASEData(e.value).then(res => {
    //   setase([{ value: "", label: "NA" }, ...(res.data).map(user => ({ value: user.id, label: user.staffName }))])
    // });
    axios.get(BASE_URL + 'staff/multiple/ase/asm/' + e.map((item)=>item.value), {
      headers: authHeader()
    }).then(res => {
      const uniqueStaffIds = new Set();
      const uniqueArr = res.data.filter(item => {
        if (!uniqueStaffIds.has(item.staffid)) {
          uniqueStaffIds.add(item.staffid);
          return true;  
        }
        return false;  
      });
      
      setase(uniqueArr.map(user => ({ value: user.staffid, label: user.staffname })))
  }).catch(error => {
      console.error('Error fetching verification status:', error);
    });
  };

  /* ASE Change */
  const onAse = (e) =>{
     setasevalue(e.map((item)=>({ value: item.value, label: item.label })));
    
  };

  const sendemail = (e) => {
    axios.post(BASE_URL + 'auth/sendverificationcode/otp', { "email": e }, {
        headers: authHeader()
    }).then(res => {
        setstatus(res.data)
        setverifydone('');
        setopttimeout('');

    }).catch(error=>{
      alert("This Email ID is Allready Register")
    })
}

const verifyemail = (e, f) => {
  let dataverify = { "email": e, "otp": f };

  axios.post(BASE_URL + 'auth/verifyEmailWithOtp', dataverify, {
      headers: authHeader()
  }).then(res => {
      // console.log('mello',res.data)
      if (res.data == 'invalid email or otp') {
          alert('Invalid OTP! Please Enter Valid OTP')
      } else if (res.data == 'time limit exceed !!! please generate new otp .') {
          alert('Your OTP has been Expired! , Please Generate New OTP')
          setopttimeout(res.data)

      } else {
          setverifydone(res.data)
      }
  })
}

  const submitform = () => {
 
    const multipleStaffDtos=[ {
      nsmid: nsmId === 0 ? [] : Array.isArray(nsmId) ? nsmId.map(item => item.value) : [],
  rsmid: rsmvalue === 0 ? [] : Array.isArray(rsmvalue) ? rsmvalue.map(item => item.value) : [],
  asmid: asmvalue === 0 ? [] : Array.isArray(asmvalue) ? asmvalue.map(item => item.value) : [],
  aseid: asevalue === 0 ? [] : Array.isArray(asevalue) ? asevalue.map(item => item.value) : [],

    }]

    let roles = [{"id":roles_id}];
    let staff = {
      staffName,
      doj,
      address,
      zones:zoneId.map((item)=>({id:item.value})),
      statezones:stateZoneId.map((item)=>({id:item.value})),
      mobileNumber,
      email,
      gender,
      multipleStaffDtos,
      roles,
      salary,
      area,
      dateOfBirth,
      bloodGroup,
      fatherName,
      spouseName,
      bankDetail,
      accountNumber,
      ifscCode,
      bankName,
      branchName,
      panNumber,
      aadharNumber,
      dateOfAnniversary,
      password,
      nsmId:0,
      rsmId:0,
      asmId:0,
      aseId:0,
      emailLoginStatus
    }


      // StaffService.getStaffEditData(id,staff).then(res=>{
      //   navigate('/staff')
      // })
      let Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })

      if (staffName.length !== 0 && doj.length !== 0 && address.length !== 0 && mobileNumber.length !== 0 && email.length !== 0 && gender.length !== 0 && salary.length !== 0 && area.length !== 0 && dateOfBirth.length !== 0 &&
        bankDetail.length !== 0 && accountNumber.length !== 0 && ifscCode.length !== 0 && bankName.length !== 0 && branchName.length !== 0 &&  emailLoginStatus!==""&&
        zoneId.length !== 0 && stateZoneId.length !== 0) {
        setError(false)
        setIsLoading(true); 

       StaffService.getStaffEditData(id,staff).then(res=>{
        {user.roles[0] === "ROLE_ASE"?navigate('/'):navigate('/Stafftable/1')}
        if(res.status==200){
          // alert("ee")
          Toast.fire({
            icon: 'success',
            title: 'Successfully Updated'
          })
        }
      }).catch(error => {
        console.error('Error adding  :', error);
        if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `
            <div>
              <p id="issueText">Why do I have this issue?</p>
              <ul>
          
              <li id="typer1">You might have an internet issue</li>
              </ul>
            </div>
          `,
          didOpen: () => {
            applyFontSizeTransition('issueText');
          }
        });
      }
      })
      .finally(() => {
        setIsLoading(false); 
      });
  
      } else {
  
        setError(true)
  
      }
  
  



  }
  const handledelete=(id)=>{
    console.log(id);
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
    
        StaffService.getDeleteStaff(id).then(res=>{

          // window.location.reload();
       console.log(res.data);
     if(res.data==200||res.data==201){
        Swal.fire(
          'Deleted!',
          'Your file has been deleted.',
          'success'
        )
        navigate("/Stafftable/1")
     }
        })
        
      }
    })
    }
    
  return (
    <MDBCard data-aos="fade">
      <div className='d-flex bd-highlight'>
        <div className="card-header card-title w-100">EDIT STAFF</div>
        <Link className='prox btn' to={`/Stafftable/${pointindex}`}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"/><polyline points="12 19 5 12 12 5"/></svg></Link>
        {user.roles[0] === 'ROLE_ADMIN' &&<a className='delete  btn' onClick={()=>handledelete(id)}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"/><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"/><line x1="10" y1="11" x2="10" y2="17"/><line x1="14" y1="11" x2="14" y2="17"/></svg></a>}
      </div>
        <Form className='product2' >
          <Row className="mb-3">
            <Form.Group as={Col} md="6" controlId="formBasicName">
              <Form.Label>Full Name*</Form.Label>
              <Form.Control type="text" autoComplete="off" style={{ borderColor: error && staffName.length === 0 ? "red" : "" }} value={staffName} name='staffName' onChange={(e) => setstaffName(e.target.value)} />
              {error && staffName.length === 0 ? <p style={{ color: error && staffName.length === 0 ? "red" : " " }}>This field is Required</p> : ""}

            </Form.Group>

            <Form.Group as={Col} md="6" controlId="formBasicName">
              <Form.Label>DOJ*</Form.Label>
              <Form.Control type="date" style={{ borderColor: error && doj.length === 0 ? "red" : "" }} autoComplete="off" name='doj' value={doj} onChange={(e) => setdoj(e.target.value)} />
              {error && doj.length === 0 ? <p style={{ color: error && doj.length === 0 ? "red" : " " }}>This field is Required</p> : ""}

            </Form.Group>
            <Form.Group as={Col} md="6" controlId="formBasicName">
              <Form.Label>Address*</Form.Label>
              <Form.Control style={{ borderColor: error && address.length === 0 ? "red" : "" }} type="text" autoComplete="off" value={address} name='name' onChange={(e) => setaddress(e.target.value)} />
              {error && address.length === 0 ? <p style={{ color: error && address.length === 0 ? "red" : " " }}>This field is Required</p> : ""}
 
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="formBasicName">
              <Form.Label>Zone*</Form.Label>
              <Select value={zoneId } isMulti options={zonesdata} style={{ borderColor: error && zoneId.length === 0 ? "red" : "" }} onChange={(e)=>onZone(e)} />
              {error && zoneId.length === 0 ? <p style={{ color: error && zoneId.length === 0 ? "red" : " " }}>This field is Required</p> : ""}

            </Form.Group>
            <Form.Group as={Col} md="6" controlId="formBasicName">
              <Form.Label>State*</Form.Label>
              <Select style={{ borderColor: error && stateZoneId.length === 0 ? "red" : "" }} isMulti value={stateZoneId}  options={statedata} onChange={(e)=>onSelect(e)}/>
              {error && stateZoneId.length === 0 ? <p style={{ color: error && stateZoneId.length === 0 ? "red" : " " }}>This field is Required</p> : ""}

            </Form.Group>
            <Form.Group as={Col} md="6" controlId="formBasicName">
              <Form.Label>Mobile No*</Form.Label>
              <Form.Control type="number" autoComplete="off" name='mobileNumber' style={{ borderColor: error && mobileNumber.length !==10 ? "red" : "" }} value={mobileNumber} onChange={(e) => setmobileNumber(e.target.value)} />
              {error && mobileNumber.length !== 10 ? <p style={{ color: error && mobileNumber.length !== 10 ? "red" : " " }}>This field is Required</p> : ""}

            </Form.Group>
            <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">OTP Login</Form.Label>
            <Form.Select value={emailLoginStatus} name='emailLoginStatus' onChange={(e)=>setemailLoginStatus(e.target.value)}>
              <option>--Select--</option>
              <option value={'Enable'}>Enable</option>
              <option value={'Disable'}>Disable</option>
            </Form.Select>
            </Form.Group>

{emailLoginStatus === "Disable" ?
          <>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
             <Form.Label>Email*</Form.Label>
             <Form.Control style={{ borderColor: error && email.length == 0 ? "red" : "" }} type="email" value={email} name='email' disabled={verifydone == "" ? false : opttimeout == "" ? true : false} autoComplete="off" onChange={(e) => setemail(e.target.value)} placeholder='Enter Email'/>
             {error && email.length === 0 ? <p style={{ color: error && email.length === 0 ? "red" : " " }}>This field is Required</p> : ""}
             </Form.Group>
          </>
             :<>
              <Form.Group as={Col} md="5" controlId="formBasicName">
             <Form.Label>Email*</Form.Label>
             <Form.Control style={{ borderColor: error && email.length == 0 ? "red" : "" }} type="email" disabled={verifydone == "" ? false : opttimeout == "" ? true : false} autoComplete="off" value={email} name='email' onChange={(e) => setemail(e.target.value)} placeholder='Enter Email'/>
             {error && email.length === 0 ? <p style={{ color: error && email.length === 0 ? "red" : " " }}>This field is Required</p> : ""}
             </Form.Group>
               {verifydone == '' ?
            <Form.Group as={Col} md="1" controlId="formBasicName">
              <Form.Label className="cname2"></Form.Label>
              <Button onClick={() => sendemail(email)}>OTP Generate</Button>


            </Form.Group> : opttimeout == "" ? <Form.Group as={Col} md="1" controlId="formBasicName">
              <Form.Label className="cname2"></Form.Label>
              <span class="snackbar_circle"><span class="checkmark">L</span></span>
            </Form.Group> : <Form.Group as={Col} md="1" controlId="formBasicName">
              <Form.Label className="cname2"></Form.Label>
              <Button onClick={() => sendemail(email)}>OTP Generate</Button>


            </Form.Group>
            }
            {status !== '' ? verifydone == '' && opttimeout == '' ?
            <Row>

              <Form.Group as={Col} md="1" controlId="formBasicName">
                <Form.Label className="cname2">Verification Code*</Form.Label>
                <Form.Control type="text" autoComplete="off" onChange={(e) => setsendotp(e.target.value)} placeholder="Enter OTP" />
              </Form.Group>

              <Form.Group as={Col} md="1" controlId="formBasicName">
                <Form.Label className="cname2"></Form.Label>
                <Button onClick={() => verifyemail(email, sendotp)}>Verify</Button>

              </Form.Group>

              <Form.Group as={Col} md="4" controlId="formBasicName">

              </Form.Group></Row> : "" : ""}
             </>

             
            }


            
            <Form.Group as={Col} md="6" controlId="formBasicName">
              <Form.Label>Gender*</Form.Label><br></br>
              <input type="radio" onChange={(e) => setgender(e.target.value)} value="male" name="mello" checked={gender === "male"} /> Male&nbsp;&nbsp;
              <input type="radio" onChange={(e) => setgender(e.target.value)} value="female" name="female" checked={gender === "female"} /> Female
              {error && gender.length === 0 ? <p style={{ color: error && email.length === 0 ? "red" : " " }}>This field is Required</p> : ""}

            </Form.Group>

            <Form.Group as={Col} md="6" controlId="formBasicName">
              <Form.Label>Designation*</Form.Label>
              <Select value={RolesValue} style={{ borderColor: error && RolesValue.length === 0 ? "red" : "" }} isDisabled options={RolesId} onChange={(e) => { onRole(e) }} />
              {error && RolesValue.length === 0 ? <p style={{ color: error && RolesValue.length === 0 ? "red" : " " }}>This field is Required</p> : ""}

            </Form.Group>

            <Form.Group as={Col} md="6" controlId="formBasicName">
              <Form.Label>Salary*</Form.Label>
              <Form.Control style={{ borderColor: error && salary.length === 0 ? "red" : "" }} type="number" autoComplete="off" name="salary" value={salary} onChange={(e) => setsalary(e.target.value)} />
              {error && salary.length === 0 ? <p style={{ color: error && salary.length === 0 ? "red" : " " }}>This field is Required</p> : ""}

            </Form.Group>
            <Form.Group as={Col} md="6" controlId="formBasicName">
              <Form.Label>Area*</Form.Label>
              <Form.Control style={{ borderColor: error && area.length === 0 ? "red" : "" }} type="text" autoComplete="off" name='area' value={area} onChange={(e) => setarea(e.target.value)} />
              {error && area.length === 0 ? <p style={{ color: error && area.length === 0 ? "red" : " " }}>This field is Required</p> : ""}

            </Form.Group>
            <Form.Group as={Col} md="6" controlId="formBasicName">
              <Form.Label>Date&nbsp;of&nbsp;Birth*</Form.Label>
              <Form.Control type="date" style={{ borderColor: error && dateOfBirth.length === 0 ? "red" : "" }} autoComplete="off" name='dateOfBirth' value={dateOfBirth} onChange={(e) => setdateOfBirth(e.target.value)} />
              {error && dateOfBirth.length === 0 ? <p style={{ color: error && dateOfBirth.length === 0 ? "red" : " " }}>This field is Required</p> : ""}

            </Form.Group>
            <Form.Group as={Col} md="6" controlId="formBasicName">
              <Form.Label>Blood&nbsp;Group</Form.Label>
              <Form.Control type="text" autoComplete="off" name='bloodGroup' value={bloodGroup} onChange={(e) => setbloodGroup(e.target.value)} />
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="formBasicName">
              <Form.Label>Father Name</Form.Label>
              <Form.Control type="text" autoComplete="off" name='fatherName' value={fatherName} onChange={(e) => setfatherName(e.target.value)} />
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="formBasicName">
              <Form.Label>Spouse Name</Form.Label>
              <Form.Control type="text" autoComplete="off" name='spouseName' value={spouseName} onChange={(e) => setspouseName(e.target.value)} />
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="formBasicName">
              <Form.Label>Bank A/c Name*</Form.Label>
              <Form.Control type="text"  style={{ borderColor: error && bankDetail.length == 0 ? "red" : "" }}  autoComplete="off" name='bankDetail' value={bankDetail} onChange={(e) => setbankDetail(e.target.value)} />
              {error && bankDetail.length === 0 ? <p style={{ color: error && bankDetail.length === 0 ? "red" : " " }}>This field is Required</p> : ""}

            </Form.Group>
            <Form.Group as={Col} md="6" controlId="formBasicName">
              <Form.Label>A/C No*</Form.Label>
              <Form.Control type="number" style={{ borderColor: error && accountNumber.length === 0 ? "red" : "" }} autoComplete="off" name='accountNumber' value={accountNumber} onChange={(e) => setaccountNumber(e.target.value)} />
              {error && accountNumber.length === 0 ? <p style={{ color: error && accountNumber.length === 0 ? "red" : " " }}>This field is Required</p> : ""}

            </Form.Group>
            <Form.Group as={Col} md="6" controlId="formBasicName">
              <Form.Label>IFSC CODE*</Form.Label>
              <Form.Control type="text" style={{ borderColor: error && ifscCode.length === 0 ? "red" : "" }}  autoComplete="off" name='ifscCode' value={ifscCode} onChange={(e) => setifscCode(e.target.value)} />
              {error && ifscCode.length === 0 ? <p style={{ color: error && ifscCode.length === 0 ? "red" : " " }}>This field is Required</p> : ""}

            </Form.Group>
            <Form.Group as={Col} md="6" controlId="formBasicName">
              <Form.Label>Bank Name*</Form.Label>
              <Form.Control style={{ borderColor: error && bankName.length === 0 ? "red" : "" }} type="text" autoComplete="off" name='bankName' value={bankName} onChange={(e) => setbankName(e.target.value)} />
              {error && bankName.length === 0 ? <p style={{ color: error && bankName.length === 0 ? "red" : " " }}>This field is Required</p> : ""}

            </Form.Group>
            <Form.Group as={Col} md="6" controlId="formBasicName">
              <Form.Label>Branch Name*</Form.Label>
              <Form.Control type="text" autoComplete="off" style={{ borderColor: error && branchName.length == 0 ? "red" : "" }} name='branchName' value={branchName} onChange={(e) => setbranchName(e.target.value)} />
              {error && branchName.length === 0 ? <p style={{ color: error && branchName.length === 0 ? "red" : " " }}>This field is Required</p> : ""}

            </Form.Group>
            <Form.Group as={Col} md="6" controlId="formBasicName">
              <Form.Label>PAN Number</Form.Label>
              <Form.Control type="text"   autoComplete="off" name='panNumber' value={panNumber} onChange={(e) => setpanNumber(e.target.value)} />
              
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="formBasicName">
              <Form.Label>AADHAR Number</Form.Label>
              <Form.Control type="number"  autoComplete="off" name='aadharNumber' value={aadharNumber} onChange={(e) => setaadharNumber(e.target.value)} />
              
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="formBasicName">
              <Form.Label>Date of Anniversary</Form.Label>
              <Form.Control type="date" autoComplete="off" name='dateOfAnniversary' value={dateOfAnniversary} onChange={(e) => setdateOfAnniversary(e.target.value)} />
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="formBasicName">
              <Form.Label>Password</Form.Label>
              {/* <Form.Control type="text" name='password' value={password} onChange={(e) => setpassword(e.target.value)} /> */}
              <Form.Control type="text" autoComplete="off" name='password' onChange={(e) => setpassword(e.target.value)} />
            </Form.Group>
           { RolesId.filter(item => item.value === RolesValue.value)
    .map(item => item.label)
    .some(label => label !== "ROLE_NSM" && label !== "ROLE_ADMIN") ? 
           <> <Form.Group as={Col} md="6" controlId="formBasicName">
              <Form.Label>NSM</Form.Label>
              <Select value={nsmId} options={nsm} isMulti onChange={(e) => onNsm(e)} />
            </Form.Group>
           {  RolesId.filter((item)=>item.value==RolesValue.value).map((item)=>item.label!=="ROLE_RSM")[0]!==false?<Form.Group as={Col} md="6" controlId="formBasicName">
              <Form.Label>RSM</Form.Label>
              <Select value={rsmvalue} isMulti options={rsm} onChange={(e) => onRsm(e)} />
            </Form.Group>:""}
            
          {  RolesId.filter((item)=>item.value==RolesValue.value).map(item => item.label)
    .some(label => label !== "ROLE_ASM" && label !== "ROLE_RSM")?<> <Form.Group as={Col} md="6" controlId="formBasicName">
              <Form.Label>ASM</Form.Label>
              <Select value={asmvalue} isMulti options={asm} onChange={(e) => onAsm(e)}/>

            </Form.Group></>:""}

          {RolesId.filter((item)=>item.value==RolesValue.value)    .map(item => item.label)
    .some(label => label !== "ROLE_ASE"&& label !== "ROLE_ASM" && label !== "ROLE_RSM")?  <Form.Group as={Col} md="6" controlId="formBasicName">
              <Form.Label>ASE</Form.Label>
              <Select value={asevalue} isMulti options={ase} onChange={(e) => onAse(e)}/>
            </Form.Group>
            :""}
            </>:""}
          </Row>
          {/* <Button variant="primary" type="button" onClick={submitform}>
            Submit
          </Button> */}

          <Button variant="primary" style={{width:'auto'}} onClick={submitform} disabled={isLoading}>
          {isLoading ? 'Loading...' : 'Submit'}
        </Button>
        </Form>
     
    </MDBCard>
  )
}
