import axios from 'axios'
import React, { useEffect, useState } from 'react'
// import Select from 'react-select/dist/declarations/src/Select'
import { BASE_URL } from '../../services/Api'
import authHeader from '../../services/auth-header'
import Select from "react-select";
import { useNavigate, useParams } from 'react-router-dom';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const Meetingedit = () => {
   
const[multiplestaff,setstaff]=useState([])
const [meetingTitle, setMeetingTitle] = useState("");
const [meetingDate, setMeetingDate] = useState(null);
const [docName, setDocName] = useState("");
const [doc, setdoc] = useState("");
const navigate = useNavigate();

const {id} = useParams();
const [editorState, setEditorState] = useState(EditorState.createEmpty());

const {pointindex} = useParams();
const [disword, setdisword] = useState(encodeURIComponent(' '))
const [dis, setdistributordata1] = useState([])
const [dis1, setdistributordata11] = useState([])
const [dis2, setdistributordata22] = useState([])

// const[setdoc]
useEffect(() => {
    axios.get(`${BASE_URL}meeting/${id}`, { headers: authHeader() })
            .then(response => {
const extracted=[response.data].map((item)=>item.staff)[0].map((item)=>({
    id:item.id,
    staff_Name:item.staffName
}))
console.log([response.data].map((item)=>item.meetingtitle)[0])
setMeetingTitle([response.data].map((item)=>item.meetingtitle)[0])
setMeetingDate([response.data].map((item)=>item.meetingdate)[0])
setdoc([response.data].map((item)=>item.doclocation)[0])
setDocName([response.data].map((item)=>item.description)[0])
                console.log([response.data].map((item)=>item.staff))
                const html = [response.data].map((item) => item.description)[0];
                const contentBlock = htmlToDraft(html);
                if (contentBlock) {
                    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                    setEditorState(EditorState.createWithContent(contentState));
                }
                setstaff(extracted)
setDist(response.data.distributor.map(use => ({ value: use.id, label: use.tradeName })))    
setRet(response.data.retailer.map(use => ({ value: use.id, label: use.tradeName })))     
setstaff(response.data.staff.map((use)=>({value: use.id, label: use.staffName})))        
console.log([response.data].map((item)=>item.doclocation)[0])
                // console.log(response.data.data.map(use => ({ value: use.id, label: use.product_Name })))
            })
}, [ ])
const [disword1, setdisword1] = useState(encodeURIComponent(' '))
const [disword2, setdisword2] = useState(encodeURIComponent(' '))
const [ret1, setRet] = useState([])
const handleretailer=(e)=>{
console.log(e)
setRet(e)
}
const [dist1, setDist] = useState([])
const handledis=(e)=>{
    setDist(e)
}
 
console.log(dist1)
    useEffect(() => {

        axios.get(`${BASE_URL}staff/page/0/200/asc/id/${disword.length?disword:encodeURIComponent(' ')}`, { headers: authHeader() })
            .then(response => {
                console.log(response)
              
                if (response.data.data == undefined) {
                    setdistributordata1(response.data.Index.map(use => ({ value: use.id, label: use.staff_Name })));
                    setData(response.data.Index)
                } if (response.data.Index == undefined) {
                    setData(response.data.data)

                    setdistributordata1(response.data.data.map(use => ({ value: use.id, label: use.staff_Name })));

                }

                // console.log(response.data.data.map(use => ({ value: use.id, label: use.product_Name })))
            })




    }, [ disword])
   const fileUrl = `http://43.225.54.138:8080/scm/${doc}`   
    const [showAllStaff, setShowAllStaff] = useState(false);
    const [data, setData] = useState([]);
console.log(ret1)
    useEffect(() => {

        axios.get(`${BASE_URL}retailer/page/0/100/asc/id/${disword1.length?disword1:encodeURIComponent(' ')}`, { headers: authHeader() })
            .then(response => {
                console.log(response)
              
                if (response.data.data == undefined) {
                    setdistributordata11(response.data.Index.map(use => ({ value: use.id, label: use.retailername })));
                    setData(response.data.Index)
                } if (response.data.Index == undefined) {
                    setData(response.data.data)

                    setdistributordata11(response.data.data.map(use => ({ value: use.id, label: use.retailername })));

                }

                // console.log(response.data.data.map(use => ({ value: use.id, label: use.product_Name })))
            })




    }, [ disword1])
    useEffect(() => {

        axios.get(`${BASE_URL}distributor/page/0/100/asc/id/${disword2.length?disword2:encodeURIComponent(' ')}`, { headers: authHeader() })
            .then(response => {
                console.log(response)
              
                if (response.data.data == undefined) {
                    setdistributordata22(response.data.Index.map(use => ({ value: use.id, label: use.trade_Name })));
                    setData(response.data.Index)
                } if (response.data.Index == undefined) {
                    setData(response.data.data)

                    setdistributordata22(response.data.data.map(use => ({ value: use.id, label: use.trade_Name })));

                }

                // console.log(response.data.data.map(use => ({ value: use.id, label: use.product_Name })))
            })




    }, [ disword2])

const [doc2, setdoc2] = useState("")

const handlesumbit = () => {
    // Map staff to an array of IDs
    const emptyBlob = new Blob([""], { type: "application/octet-stream" });
    const contentState = editorState.getCurrentContent();
    const description = draftToHtml(convertToRaw(contentState));
    //   let  doc3 = doc.replace(/^"(.*)"$/, '$1');
    const staff = [multiplestaff.map((item)=>({id:item.value}))][0];
    const ret=[ret1.map((item)=>({id:item.value}))][0];     
    const dist=[dist1.map((item)=>({id:item.value}))][0];
    // Creating FormData object
    const formData = new FormData();
    formData.append('meetingtitle', meetingTitle);
    
    // Append 'doc' only if doc2 is not empty
    // if (doc2.trim() !== "") {
        // formData.append('doc', doc);

      doc2?formData.append('doc', doc2):formData.append('doc', emptyBlob, 'empty-file.txt');

    // }
    formData.append('description',description ); 

    formData.append('meetingdate', meetingDate);
    formData.append('st', JSON.stringify(staff));
    formData.append('ret', JSON.stringify(ret));
    formData.append('dist', JSON.stringify(dist));
    // Sending data as FormData using Axios
    axios.put(BASE_URL + `meeting/${id}`, formData, {
        headers: {
            ...authHeader(), 
            'Content-Type': 'multipart/form-data',  
        },
    })
    .then(response => {
        console.log('PUT request successful:', response.data);
        // Handle success
        navigate("/Meettable/1"); 

    })
    .catch(error => {
        console.error('Error:', error);
        // Handle error
    });
}

    // const handlesumbit = () => {
    //     // const staff = 15; // Assuming staff is an ID or similar
    //     const staff=[multiplestaff.map((item)=>({id:item.id}))][0]
    //     // Adjust the file path accordingly
    //     // const doc = "C:\\Users\\lnv111\\Pictures\\steel.jpeg";
    // doc?console.log("doc"):console.log("dco2")
    //     // Creating FormData object
    //     const formData = new FormData();
    //     formData.append('meetingtitle', meetingTitle);
    //     // if (doc2.trim() !== "") {
    //         formData.append('doc', doc2);
    //     // }
    //             formData.append('meetingdate',meetingDate ); 
    //     formData.append('st', JSON.stringify(staff));

        
    //     // Sending data as FormData
    //     axios.put(BASE_URL + `meeting/${id}`, formData, {
    //         headers: authHeader()
    //     })
    //     .then(response => {
    //         console.log('Post request successful:', response.data);
    //         // Handle success
    //     })
    //     .catch(error => {
    //         console.error('Error:', error);
    //         // Handle error
    //     });
        
    // }


    // Assuming multiplestaff is your array of staff members
    const maxColumns = 3; // Maximum number of columns
    const visibleStaff = showAllStaff ? multiplestaff : multiplestaff.slice(0, maxColumns);

    const handleToggleShowAllStaff = () => {
        setShowAllStaff(prevShowAllStaff => !prevShowAllStaff);
    };
    const disid = (e) => {
     if(multiplestaff.filter((item)=>item.id==e).map((item)=>item.id)[0]!==e)
     {    const staffDetails = data.filter((item) => item.id === e);
        console.log(staffDetails);

         setstaff(prevStadddetails => [...prevStadddetails, ...staffDetails]);}else{
            alert("Already Exist");
        }
    }
console.log([multiplestaff.map((item)=>({id:item.id}))][0])

const handlestaff=(e)=>{
    setstaff(e)
}
const handle=(e)=>{
    // alert('delete'+e)
    // setstaff( multiplestaff.filter(item => item.id !== e))
}
function downloadFile(url) {
    fetch(url)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.blob();
      })
      .then(blob => {
        // Create a temporary link element
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(blob);
        
        // Extract filename from URL and assign it to the download attribute
        const filename = url.substring(url.lastIndexOf('/') + 1);
        downloadLink.download = filename;
        
        // Append the link to the body
        document.body.appendChild(downloadLink);
        
        // Programmatically click the link to trigger the download
        downloadLink.click();
        
        // Clean up: remove the link and revoke the object URL
        document.body.removeChild(downloadLink);
        URL.revokeObjectURL(downloadLink.href);
      })
      .catch(error => {
        console.error('Error downloading file:', error);
        alert('Failed to download the file. Please try again later.');
      });
  }
  const handleClick = () => {
    navigate("/Meettable/1"); 

};
  return (
    <div>
        <div className=' card'>
            <div className='card-body'>
                <div className='card-header card-title'>
                    <h1>Minutes of Meetings</h1>
                </div>
                <button className='btn btn-primary btn-sm' onClick={()=>{handleClick()}}>Back</button>
            <div className='row'>
                
                <div className='col'>
                {/* <img src={"http://43.225.54.138:8080/scm/" + doc } 
      style={{ width: '100px', height: '100px', margin: '5px' }} 
/> */}



                    <label className='form-label'>Meeting Title</label>
                <input type='text' placeholder='Title' value={meetingTitle} onChange={(e)=>{setMeetingTitle(e.target.value)}} className='form-control'/>
                </div>
             </div>
             <br></br>
             {/* <div className='row'>
                <div className='col-3'>
                <label className='form-label'>Date</label>
                <input type='Date' placeholder='Date' value={meetingDate} onChange={(e)=>setMeetingDate(e.target.value)} className='form-control'/>
                </div>
                <div className='col'>
                    <label className='form-label'>Add Staff  </label>
                    <Select options={dis} onInputChange={(inputValue) => setdisword(inputValue)} onChange={(options) => disid(options.value)}/>
                </div>
             </div><br></br> */}
            <div className='row'>
                <div className='col-3'>
                <label className='form-label'>Date</label>
                <input type='Date' placeholder='Date' value={meetingDate} onChange={(e) => setMeetingDate(e.target.value)} className='form-control'/>
                </div>
                <div className='col-12'>
                    <label className='form-label'>Add Staff  </label>
                    <Select options={dis} isMulti value={multiplestaff} onInputChange={(inputValue) => setdisword(inputValue)} onChange={(e) => handlestaff(e)} />
                </div>
                <div className='col-12'>
                    <label className='form-label'>Add Distrbutor  </label>
                    <Select options={dis2} isMulti value={dist1}  onInputChange={(inputValue) => setdisword2(inputValue)} onChange={(e) => handledis(e)}/>
                </div>
                <div className='col-12'>
                    <label className='form-label'>Add Retailer  </label>
                    <Select options={dis1} isMulti value={ret1} onInputChange={(inputValue) => setdisword1(inputValue)}  onChange={(e) => { handleretailer(e) }} />

                    {/* <Select options={dis1} onInputChange={(inputValue) => setdisword1(inputValue)} onChange={(options) => disid(options.value)}/> */}
                </div>
             </div><br></br>
             <div className='row'>
              
                <div className='col-3'>
                {/* <input type='text' placeholder='Staff' className='form-control'/> */}
                </div>
             </div><br></br>
 
<br></br>
{/* <textarea className='form-control' value={docName} onChange={(e)=>{setDocName(e.target.value)}} placeholder='Desription'></textarea><br></br> */}
<Editor
                                editorState={editorState}
                                onEditorStateChange={setEditorState}
                                wrapperClassName="demo-wrapper"
                                editorClassName="demo-editor"
                            />
<div className='row'>
    <div className='col-3'>
 {doc2?   "":<a href={`http://43.225.54.138:8080/scm/${doc}`} download target="_blank">
  Download Document
</a>}



        <input type='file' onChange={e =>{ setdoc2(e.target.files[0]); setdoc("")}} className='form-control'/>
    </div>
</div>

<button className='btn btn-primary' onClick={()=>handlesumbit()}>Submit</button>
            </div>
        </div>

    </div>
  )
}

export default Meetingedit