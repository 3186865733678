import axios from 'axios';
import { MDBCard } from 'mdbreact';
import React, { useEffect, useState } from 'react'
import { Accordion, Button, Col, Form, Row, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import Swal from 'sweetalert2';
import { changecostpurchasereturnupdate, changepurchasereturnupdatediscount, changepurchasereturnupdatetext, purchasereturnupdateaddtocart, purchasereturnupdatecalmeasurement, purchasereturnupdategetdata, purchasereturnupdatemeasurement, purchasereturnupdateqtykgs, purchasereturnupdateqtypcs, removefromcartfrompurchasereturnupdate } from '../../redux/Action/purchasereturnupdate';
import { BASE_URL } from '../services/Api';
import authHeader from '../services/auth-header';
import CompanyService from '../services/CompanyService';

export default function Edit() {
    const { id } = useParams();

    const [branch,setbranch] = useState('');
    const [branchdata,setbranchdata] = useState('');
    const [warehouse,setwarehouse]=useState('');
    const [warehousedata,setwarehousedata]=useState('');
    const [supplier,setsupplier] =useState('');
    const [supplierdata,setsupplierdata] =useState('');
    const [supplierSubContacts,setsupplierSubContacts] =useState('');
    const [supplierSubContactsvalue,setsupplierSubContactsvalue] =useState('');
    const [supplierSubContactsdata,setsupplierSubContactsdata] =useState([]);
    const [purchasereturndate,setpurchasereturndate] = useState('');
    const [paymentTerms,setpaymentTerms] = useState('');
    const [debitnoteno,setdebitnoteno] = useState('');
    const [ewaybillno,setewaybillno] = useState('');
    const [originalinvoiceno,setoriginalinvoiceno] = useState('');
    const [originalinvoicenodate,setoriginalinvoicenodate] = useState('');
    const [otherrefernce,setotherrefernce] = useState('');
    const [shippingaddress,setshippingaddress] = useState('');
    const [remarks,setremarks] = useState('');
    const [productdata, setproductdata] = useState([]);
    const [shippingcharge, setShipping] = useState(0);
    const [round, setRound] = useState(0);
    const [bstateid, setbstateid] = useState('');
    const [sstateid, setsstateid] = useState('');
    const [setsellindata, setSetsellindata] = useState([])
    const handleChange = (e) => setseller({ ...seller, [e.target.name]: e.target.value });
    const handleChange1 = (e) => setbuyer({ ...buyer, [e.target.name]: e.target.value });
    const [seller, setseller] = useState({});
    const [buyer, setbuyer] = useState({});
    const [supTyp, setsupTyp] = useState('');
    const [no, setno] = useState('');
    const [dt1, setdt1] = useState('');
    const [distributordata111, setdistributordata111] = useState([])
    const [distance, setdistance] = useState('');
    const [transName, settransName] = useState('');
    const [transId, settransId] = useState('');
    const [transDocDt1, settransDocDt1] = useState('');
    const [transDocNo, settransDocNo] = useState('');
    const [vehNo, setvehNo] = useState('');
    const [vehType, setvehType] = useState('');

    const vechiletype=[
        { "id": "R", "label": "Regular" },
        { "id": "O", "label": "ODC" }
      ]
    const handletrans=(e)=>{
        settransId(e.label)
        console.log(e)
        console.log((data11.filter((item)=>item.id==e.value).map((item)=>item.transporter_Name))
        )
        settransName((data11.filter((item)=>item.id==e.value).map((item)=>item.transporter_Name))[0])
        } 
        const[data11,setdata11]=useState([])
        const [transMode, settransMode] = useState('');

    const [isLoading, setIsLoading] = useState(false);
    const applyFontSizeTransition = (elementId) => {
      const element = document.getElementById(elementId);
      element.style.transition = 'font-size 2s';
      element.style.fontSize = '30px';
      element.style.fontWeight = '600';
      // font-weight: 600;
      setTimeout(() => {
         
        document.getElementById("typer1").classList.add('promote');
        element.style.fontSize = '24px';
      }, 1000);
    };

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const purchaseReturnItems = useSelector((state) => state.purchasereturnupdatedata);
    let net_Amount = purchaseReturnItems.length && purchaseReturnItems.map(item => Math.round(Number(item.total))).reduce((prev, next) => Math.round(prev - (-next)));
    let gst = purchaseReturnItems.length && purchaseReturnItems.map(item => (item.gstvalue)).reduce((prev, next) => (prev - (-next)));
    let aa = [];
    purchaseReturnItems.map(user=>{
        let igst_value = user.igst;
        let total_value = user.total;
        let total_amou = ((Number(shippingcharge)/Number(net_Amount))* Number(total_value));
        let add_amou = Number(total_amou) - (-Number(total_value));
        let final_amou = ((Number(add_amou)/100)*Number(igst_value)).toFixed(2); 
        aa.push(final_amou)

        // console.log("mello",((Number(add_amou)/100)*Number(igst_value)).reduce((prev, next) => Math.round(prev - (-next))) );
    })
    let cgstVal = purchaseReturnItems.length && purchaseReturnItems.map(item => (item.gstvalue)).reduce((prev, next) => (prev - (-next)));
    let total_per = aa.length && aa.map(user=>user).reduce((prev, next) => (prev - (-next)));
    let netAmount = Number(net_Amount) + Number(shippingcharge);
    let grandTotal = Number(net_Amount)  + Number(shippingcharge) + Number(round) + Number(total_per);
    let igstTax = Number(total_per);
    let cgstTax = (Number(total_per)/2);
    let sgstTax = (Number(total_per)/2);
    let completetotalwithoutshipping=  purchaseReturnItems.length && purchaseReturnItems.map(item => Math.round(Number(item.amount))).reduce((prev, next) => Math.round(prev - (-next)));



    let da_ta = purchaseReturnItems.length && purchaseReturnItems.map((user) => ({
        "brand": user.brand,
        "calunitofmeasurement": user.calunitofmeasurement,
        "capacity": user.capacity,
        "category": user.category,
        "cgst": user.cgst,
        // "cgstLedger": user.cgstLedger,
        "diameter": user.diameter,
        "dlp": user.dlp,
        "eanCode": user.eanCode,
        "grossamount": user.grossamount,
        "gstvalue": user.gstvalue,
        "hsnCode": user.hsnCode,
        "igst": user.igst,
        // "igstLedger": user.igstLedger,
        "netAmount": user.total,
        "product": { "id": user.product.id },
        "productName": user.productName,
        "productType": user.productType,
        "purquantity": user.purquantity,
        "purquantitykgs": user.purquantitykgs,
        // "schemeDiscount": user.schemeDiscount,
        "sgst": user.sgst,
        "sgstLedger": user.sgstLedger,
        "shortName": user.shortName,
        // "slNo": user.soid,
        "standardQtyPerBox": user.standardQtyPerBox,
        "total": user.total,
        "tradeDiscount": user.tradeDiscount,
        "unitofmeasurement": user.unitofmeasurement,
        "uomPrimary": user.uomPrimary,
        "uomSecondary": user.uomSecondary,
        // "SlNo":user.soid,
        "prdDesc": user.PrdDesc,
        "isServc": "N",
        "qty": user.purquantitykgs,
        "freeQty": user.purquantity,
        "unit": user.unitofmeasurement,
        "totAmt":Number(user.grossamount),
        "discount": ((Number(user.grossamount) / 100) * Number(user.tradeDiscount)).toFixed(2),
        "assAmt": user.total,
        "gstRt": user.igst,
        "igstAmt": seller.stcd === buyer.stcd ? 0 : Number(user.gstvalue),
        "cgstAmt": seller.stcd === buyer.stcd ? ((user.gstvalue) / 2) : 0,
        "sgstAmt": seller.stcd === buyer.stcd ? ((user.gstvalue) / 2) : 0,
        "totItemVal": user.amount


    }));
    function roundData(e){
        if(e){
            let net_Amount = purchaseReturnItems.length && purchaseReturnItems.map(item => Math.round(Number(item.total))).reduce((prev, next) => Math.round(prev - (-next)));
            let gst = purchaseReturnItems.length && purchaseReturnItems.map(item => (item.gstvalue)).reduce((prev, next) => (prev - (-next)));
            // console.log("mello", purchaseReturnItems);
            let aa = [];
            purchaseReturnItems.map(user=>{
                let igst_value = user.igst;
                let total_value = user.total;
                let total_amou = ((Number(shippingcharge)/Number(net_Amount))* Number(total_value));
                let add_amou = Number(total_amou) - (-Number(total_value));
                let final_amou = ((Number(add_amou)/100)*Number(igst_value)).toFixed(2); 
                aa.push(final_amou)
        
                // console.log("mello",((Number(add_amou)/100)*Number(igst_value)).reduce((prev, next) => Math.round(prev - (-next))) );
            })
            let total_per = aa.length && aa.map(user=>user).reduce((prev, next) => (prev - (-next)));
          
        
            let netAmount = Number(net_Amount) + Number(shippingcharge);
            let grandTotal = Number(net_Amount)  + Number(shippingcharge) + Number(total_per);
      
             let grand_Total = Math.ceil(grandTotal);
        
             if(grand_Total > grandTotal){
                 let rou_nd = (Number(grand_Total) - Number(grandTotal)).toFixed(2);
                 setRound(rou_nd);
             } else{
                 setRound(0)
             }
               
              
        } else if(e==''){
            setRound(0)
        }
    }
    const [disword11, setdisword11] = useState(encodeURIComponent(' '))
    // const [distributordata111, setdistributordata111] = useState([])
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get(
            `${BASE_URL}transporter/page/0/200/asc/id/${disword11.length ? disword11 : encodeURIComponent(' ')}`,
            { headers: authHeader() }
          );
    
          let options = [];
          if (response.data.Index) {
            setdata11(response.data.Index)
            options = response.data.Index.map(item => ({
              value: item.id,
              label: item.transporter_Gstin,
            }));
          } else if (response.data.data) {
            setdata11(response.data.data)
            options = response.data.data.map(item => ({
              value: item.id,
              label: item.transporter_Gstin,
            }));
          } else {
            console.warn('Unexpected response structure:', response.data);
          }
    
          // Add a placeholder option at the beginning
          const optionsWithPlaceholder = [
            // { value: '', label: 'Select an option' }, // Placeholder option
            ...options,
          ];
    
          setdistributordata111(optionsWithPlaceholder);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
    
      fetchData();
    }, [disword11]);
    const getPurchaseReturn = ( ) => {
        axios.get(BASE_URL + 'pr/'+id,{headers:authHeader()}).then(res=>{
            // console.log('mello',res.data)
            setbranch({"id":res.data.branch.id});
            setsupTyp(res.data.supTyp)
            setno(res.data.no)
             setdt1(res.data.dt)
             setbuyer({
                gstin: res.data.bgstin,
                loc: res.data.bloc,
                pos: res.data.pos,
                stcd: res.data.bstcd,
                addr1: res.data.baddr1,
                lglNm: res.data.blglNm,
                pin: res.data.bpin,
                ph: res.data.bph,
                em: res.data.bem
            });
            setbranchdata(res.data.branch);
            setwarehouse({"id":res.data.warehouse.id});
            setwarehousedata(res.data.warehouse);
            setsupplier({"id":res.data.supplier.id});
            onChangeSupplier(res.data.supplier)
            setsupplierdata(res.data.supplier);
            setsupplierSubContacts(res.data.supplierSubContacts == null?null:{"id":res.data.supplierSubContacts.id})
            setsupplierSubContactsvalue(res.data.supplierSubContacts == null?'':res.data.supplierSubContacts)
            setsupplierSubContactsdata(res.data.supplier.supplierSubContacts == null?'':res.data.supplier.supplierSubContacts)
           setpurchasereturndate(res.data.purchasereturndate);
           setpaymentTerms(res.data.paymentTerms);
           setdebitnoteno(res.data.debitnoteno);
           setewaybillno(res.data.ewaybillno);
           setoriginalinvoiceno(res.data.originalinvoiceno);
           setoriginalinvoicenodate(res.data.originalinvoicenodate);
           setotherrefernce(res.data.otherrefernce);
           setshippingaddress(res.data.shippingaddress);
           setremarks(res.data.remarks);
           setShipping(res.data.shippingcharge);
           setRound(res.data.roundvalue);
           setbstateid(res.data.branch.states.id);
           setsstateid(res.data.supplier.states.id);
        })
    };
    let date_value = new Date(purchasereturndate);
    const defaultValue = date_value.toLocaleDateString('en-CA');
    let date_value1 = new Date(originalinvoicenodate);
    const defaultValue1 = date_value1.toLocaleDateString('en-CA');

        /* Get Product Data */
        const getproductdata = () => {
            axios.get( BASE_URL + 'product/page/0/100/asc/id/%20',{
                headers:authHeader()
            }).then(res=>{
    
                setproductdata((res.data.Index).map(use => ({ value: use.id, label: use.product_Name + ' - ' + use.ean_Code + ' - ' + use.short_Name })));
            })
        };
    
        const customFilter = (e) => {
            let name = e;
            axios.get( BASE_URL + 'product/page/0/100/asc/id/'+name,{
                headers:authHeader()
            }).then(res=>{
             setproductdata((res.data.data).map(use=>({ value: use.id, label: use.product_Name + ' - ' + use.ean_Code + ' - ' + use.short_Name })))
            })
        }

    useEffect(()=>{
        getPurchaseReturn();
        getproductdata();
        getcompanydata();
        dispatch(purchasereturnupdategetdata(id))
    },[]);

    const onChnagecontactname = (e) => {
        setsupplierSubContacts({"id":e})

    }

    const addData = ( e ) => {
        const filteredItems = purchaseReturnItems.filter(item => item.product.id === e.value);

        if (filteredItems.length == "0") {
            dispatch(purchasereturnupdateaddtocart(e.value));
            setShipping("0");
        } else {
            Swal.fire({
                title: 'This Product is Already Added',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showConfirmButton: false,
                showCancelButton: true,
                cancelButtonColor: 'red'
            })
        }
      
    };


    /* get Shipping Charges */
    const settingship = (e) => {

        if (e == "") {

            setShipping("");
        } else {


            setShipping(e);
        }

    }

    /* get Round Value */
    const settinground = (e) => {
        if (e == "") {

            setRound("");
        } else {

            setRound(e);
        }

    }

    const sgstcheck = () => {

        let aa = 0

    

            if (bstateid === sstateid) {

                aa = sgstTax;

            } else {

                aa = 0;

            }

        
        return aa;
    };

    const cgstcheck = () => {

        let bb = 0
       

            if (bstateid === sstateid) {

                bb = cgstTax;

            } else {

                bb = 0;

            }
        
        return bb;
    }
 const onChangeSupplier = (e) => {
        // setSupplier({ "id": e.value });
        // setSuppliervalue({ value: e.value, label: e.label })
        // const selectedUser = supplierAllData.find(user => user.id === e.value);
let selectedUser=e
        if (selectedUser) {
             console.log(selectedUser);
    
            //  setcontactnamedata(selectedUser.supplierSubContacts);
            // setsupplierDetails(selectedUser);
            setsstateid(selectedUser.states.id);
    
            //  setbuyer({
            //     gstin: selectedUser.gstno,
            //     loc: selectedUser.cities,
            //     pos: selectedUser.statecode,
            //     stcd: selectedUser.states.id,
            //     addr1: selectedUser.address,
            //     lglNm: selectedUser.suppliername,
            //     pin: selectedUser.zipcode,
            //     ph: selectedUser.phonenumber,
            //     em: selectedUser.email
            // });
        } else {
            // Handle the case where no user is found
            console.warn('No user found with id:', e.value);
        }
        
    };
    let getcompanydata = () => {
        const id = 1;
        CompanyService.getcompany(id).then((res) => {
            const transformedData = {
                "gstin": res.data.gst,
                "lglNm": res.data.name,
                "addr1": res.data.address,
                "loc": res.data.cities,
                "pin": parseInt(res.data.zipcode),
                "stcd": res.data.states.state_code,
                "ph": res.data.phone,
                "em": res.data.email
            };
            setSetsellindata(transformedData)
            console.log("mello", [transformedData].map((item) => item.gstin));
        });
    };


    const igstcheck = () => {

        let cc = 0
        

            if (bstateid === sstateid) {

                cc = 0;

            } else {

                cc = igstTax;

            }

        return cc;
    }

    const submitdata = ( ) => {
        setIsLoading(true); 
        const tranDtls={supTyp}
        let igst = igstcheck();
        let cgst = cgstcheck();
        let sgst = sgstcheck();
        let grandtotal = grandTotal;
        let roundvalue = round;
        let docDtls={"dt":dt1,"no":no}
        let valDtls = { "assVal": net_Amount, "cgstVal": Number(seller.stcd) === Number(buyer.stcd) ? (cgstVal / 2) : 0, "sgstVal": seller.stcd === buyer.stcd ? (cgstVal / 2) : 0, "igstVal": seller.stcd === buyer.stcd ? 0 : cgstVal, "othChrg": shippingcharge, "totInvVal": completetotalwithoutshipping, "cesVal": 0, "stCesVal": 0 }

        const prsubmitdata = {
            warehouse, supplier, debitnoteno, ewaybillno, originalinvoiceno, originalinvoicenodate, otherrefernce, purchasereturndate,
             shippingaddress, remarks, supplierSubContacts, purchaseReturnItems:da_ta,
            igst, cgst, sgst, grandtotal,valDtls:valDtls,tranDtls,docDtls,sellerDtls:setsellindata,buyerDtls:buyer, netAmount, shippingcharge, roundvalue, branch, paymentTerms
        };
        console.log(prsubmitdata)

        axios.put(BASE_URL + 'pr/'+id, prsubmitdata, {
            headers: authHeader()
        }).then(res => {
            navigate('/PurchaseReturnTable/1')
        }).catch(error => {
            console.error('Error adding  :', error);
            if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `
                <div>
                  <p id="issueText">Why do I have this issue?</p>
                  <ul>
              
                  <li id="typer1">You might have an internet issue</li>
                  </ul>
                </div>
              `,
              didOpen: () => {
                applyFontSizeTransition('issueText');
              }
            });
          }
          }).finally(() => {
            setIsLoading(false); 
          });
    }
    const stype = [
        { "id": "B2B", "label": "Business to Business" },
        { "id": "SEZWP", "label": "SEZ with payment" },
        { "id": "SEZWOP", "label": "SEZ without payment" },
        { "id": "EXPWP", "label": "Export with Payment" },
        { "id": "EXPWOP", "label": "Export without payment" },
        { "id": "DEXP", "label": "Deemed Export" }
    ]
    const handlesuptype=(e)=>{
        setsupTyp(e.id)
     }
     const modes1=[
        { "id": 1, "label": "Road" },
        { "id": 2, "label": "Rail" },
        { "id": 3, "label": "Air" },
        { "id": 4, "label": "Ship" },
        { "id": 5, "label": "In Transit" }
      ]
  return (
    <MDBCard className="company">
    <h1>Update Purchase Return </h1>
    {/* PurchaseReturnTable */}
    <div className='p-2 w-100 bd-highlight'><Link to={`/PurchaseReturnTable/1`}><button class="btn btn-sm btn-primary">back</button></Link></div>

    <Form className="product1">
        <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
                <Accordion.Header>Purchase Return Data</Accordion.Header>
                <Accordion.Body>
                    <Row className="mb-1">
                        <Form.Group as={Col} md="4" controlId="formBasicName">
                            <Form.Label className="label">Branch Name</Form.Label>
                            <Form.Control type="text" name='branchname' value={branchdata.branchname} readOnly />
                            
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="formBasicName">
                            <Form.Label className="label">WareHouse Name</Form.Label>
                            <Form.Control type="text" name='name' value={warehousedata.name} readOnly />

                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="formBasicName">
                            <Form.Label className="label">Supplier Company</Form.Label>
                            <Form.Control type="text" name='companyname' value={supplierdata.companyname} readOnly />

                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="formBasicName">
                            <Form.Label className="label">Contact Name</Form.Label>
                            <Form.Select name='supplierSubContactsvalue' value={supplierSubContactsvalue.id} onChange={(e) => { onChnagecontactname(e.target.value) }}>
                                <option>--Select--</option>
                                {supplierSubContactsdata.map(user =>
                                    <option key={user.id} value={user.id}>{user.contactname}</option>
                                )
                                }
                            </Form.Select>
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="formBasicName">
                            <Form.Label className="label">Date</Form.Label>
                            <Form.Control type="date" value={defaultValue} onChange={(e) => setpurchasereturndate(e.target.value)} />
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="formBasicName">
                            <Form.Label className="label">Terms of Payment</Form.Label>
                            <Form.Control type='text'  name="paymentTerms" value={paymentTerms} onChange={(e) => setpaymentTerms(e.target.value)} autoComplete="off" />
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="formBasicName">
                            <Form.Label className="label"> Purchase Return Note No</Form.Label>
                            <Form.Control type="text" value={debitnoteno} name="debitnoteno" onChange={(e) => setdebitnoteno(e.target.value)} />
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="formBasicName">
                            <Form.Label className="label">Ewaybill No</Form.Label>
                            <Form.Control type="text" value={ewaybillno} name='ewaybillno' onChange={(e) => setewaybillno(e.target.value)} />
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="formBasicName">
                            <Form.Label className="label">Original Invoice No</Form.Label>
                            <Form.Control type="text" value={originalinvoiceno} name='originalinvoiceno' onChange={(e) => setoriginalinvoiceno(e.target.value)} />
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="formBasicName">
                            <Form.Label className="label">Original Invoice No Date</Form.Label>
                            <Form.Control type="date" value={defaultValue1} onChange={(e) => setoriginalinvoicenodate(e.target.value)} />
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="formBasicName">
                            <Form.Label className="label">Other Reference</Form.Label>
                            <Form.Control type="text" value={otherrefernce} name='otherrefernce' onChange={(e) => setotherrefernce(e.target.value)} />
                        </Form.Group>
                        {/* <Form.Group as={Col} md="8" controlId="formBasicName">
                            <Form.Label className="label">Owner</Form.Label>
                            <Form.Control type="text" autoComplete="off" />
                        </Form.Group> */}
                        {/* <Form.Group as={Col} md="4" controlId="formBasicName">
                            <Form.Label className="label">Tax Type</Form.Label>
                            <Form.Select value={taxtype} onChange={(e) => settaxtype(e.target.value)} disabled>
                                <option>--Select--</option>
                                <option value='withtax'>With Tax</option>
                                <option value='withouttax'>Without Tax</option>
                            </Form.Select>
                        </Form.Group> */}
                    </Row>
                </Accordion.Body>
            </Accordion.Item>
            {/* <Accordion.Item eventKey="1">
                <Accordion.Header>Billing Address</Accordion.Header>
                <Accordion.Body>
                     <Row className="mb-1">
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                            <Form.Label className="label">Supplier Address</Form.Label>
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="formBasicName">
                            <Form.Label className="label">Supplier Details</Form.Label>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                            <Form.Label className="label">Address : -</Form.Label>
                            <span>&nbsp;{supplierdata.address}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                            <Form.Label className="label">Name : -</Form.Label>
                            <span>&nbsp;{supplierSubContactsvalue == "" ? supplierdata.suppliername : supplierSubContactsvalue.contactname}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                            <Form.Label className="label">City : -</Form.Label>
                            <span>&nbsp;{supplierdata.cities}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                            <Form.Label className="label">Phone No : -</Form.Label>
                            <span>&nbsp;{supplierSubContactsvalue == "" ? supplierdata.phonenumber : supplierSubContactsvalue.phoneno}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                            <Form.Label className="label">Country : -</Form.Label>
                            <span>{"India"}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                            <Form.Label className="label">Email Id : -</Form.Label>
                            <span>{supplierSubContactsvalue == "" ? supplierdata.email : supplierSubContactsvalue.email}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                            <Form.Label className="label">State : -</Form.Label>
                            <span>{supplierstate}</span>
                        </Form.Group>
                    </Row> 
                </Accordion.Body>
            </Accordion.Item> */}
            <Accordion.Item eventKey="2">
                <Accordion.Header>Shipping Address</Accordion.Header>
                <Accordion.Body>
                    <Row className="mb-1">
                        <Form.Group as={Col} md="12" controlId="formBasicName">
                            <Form.Label className="label">Address</Form.Label>
                            <Form.Control as="textarea" value={shippingaddress} name='shippingaddress' onChange={(e) => setshippingaddress(e.target.value)} rows={3}  placeholder='Enter Shipping Address' />
                        </Form.Group>
                    </Row>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
                <Accordion.Header>Details</Accordion.Header>
                <Accordion.Body>
                    <Row className="mb-1">
                        <Form.Group as={Col} md="12" controlId="formBasicName">
                            <Form.Label className="label">Remarks</Form.Label>
                            <Form.Control as="textarea" value={remarks} name='remarks' onChange={(e) => setremarks(e.target.value)} rows={3}  placeholder='Enter Remarks' />
                        </Form.Group>
                    </Row>
                </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="4">
                        <Accordion.Header>GST Details</Accordion.Header>
                        <Accordion.Body>
                            <Row className="mb-1">
                                <h1>Transport Details</h1>
                                {/* <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Tax Type</Form.Label>
                                    <Form.Control type="text" autoComplete="off" placeholder="Enter Tax Type" name="taxSch" onChange={(e) => settaxSch(e.target.value)} />
                                </Form.Group> */}
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Type of supply:{supTyp}</Form.Label>
 
                                    {/* <Form.Control type="text" autoComplete="off" placeholder="Enter Type of supply" onChange={(e) => setsupTyp(e.target.value)} /> */}
                                </Form.Group>
                                <h1>Document Details</h1>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Document No</Form.Label>
                                    <Form.Control type="text" value={no} autoComplete="off" placeholder="Enter Document No" onChange={(e) => setno(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Document Date</Form.Label>
                                    <Form.Control type="date" value={dt1} autoComplete="off" onChange={(e) => setdt1(e.target.value)} />
                                </Form.Group>
                                <h1>Seller Details</h1>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">GSTIN of Seller </Form.Label>
                                    <Form.Control type="text" value={[setsellindata].map((item) => item.gstin)} disabled autoComplete="off"  name="gstin" placeholder="Enter GSTIN of Supplier" onChange={(e) => {handleChange(e); }} />
                                </Form.Group>
                                {/* value={[setsellindata].map((item) => item.gstin)} disabled */}
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Legal Name</Form.Label>
                                    <Form.Control type="text" autoComplete="off" value={[setsellindata].map((item) => item.lglNm)} disabled name="lglNm" placeholder="Enter Legal Name" onChange={(e) => handleChange(e)} />
                                </Form.Group>
                                {/* value={[setsellindata].map((item) => item.lglNm)} disabled  */}
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Address1</Form.Label>
                                    <Form.Control type="text"  autoComplete="off" value={[setsellindata].map((item) => item.addr1)} disabled name="addr1" placeholder="Enter Address1" onChange={(e) => handleChange(e)} />
                                </Form.Group>
                                {/* value={[setsellindata].map((item) => item.addr1)} disabled */}
                                {/* <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Address2</Form.Label>
                                    <Form.Control type="text" autoComplete="off" name="addr2" placeholder="Enter Address2" onChange={(e) => handleChange(e)} />
                                </Form.Group> */}
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Location</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Location"  value={[setsellindata].map((item) => item.loc)} disabled autoComplete="off" name="loc" onChange={(e) => handleChange(e)} />
                                </Form.Group>
                                {/* value={[setsellindata].map((item) => item.loc)} disabled */}
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">PinCode</Form.Label>
                                    <Form.Control type="text"  placeholder="Enter PinCode" name="pin" value={[setsellindata].map((item) => item.pin)} disabled autoComplete="off" onChange={(e) => handleChange(e)} />
                                </Form.Group>
                                {/* value={[setsellindata].map((item) => item.pin)} disabled */}
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">State Code</Form.Label>
                                    <Form.Control type="text"  placeholder="Enter State Code"  value={[setsellindata].map((item) => item.stcd)} disabled autoComplete="off" name="stcd" onChange={(e) => handleChange(e)} />
                                </Form.Group>
                                {/* value={[setsellindata].map((item) => item.stcd)} disabled */}
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Phone Number</Form.Label>
                                    <Form.Control type="text"  placeholder="Enter Phone Number"  value={[setsellindata].map((item) => item.ph)} disabled name="ph" autoComplete="off" onChange={(e) => handleChange(e)} />
                                </Form.Group>
                                {/* value={[setsellindata].map((item) => item.ph)} disabled */}
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Email Id</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Email Id"  disabled value={[setsellindata].map((item) => item.em)}  name="em" autoComplete="off" onChange={(e) => handleChange(e)} />
                                </Form.Group>
                                {/* disabled value={[setsellindata].map((item) => item.em)} */}
                                <h1>Buyer Details</h1>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">GSTIN of Buyer</Form.Label>
                                    <Form.Control type="text" placeholder="Enter GSTIN of Buyer" value={buyer.gstin || ""} name="gstin" autoComplete="off" onChange={(e) => handleChange1(e)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Legal Name</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Legal Name" value={buyer.lglNm || ""} name="lglNm" autoComplete="off" onChange={(e) => handleChange1(e)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">State Code of Place of Supply</Form.Label>
                                    <Form.Control type="text" placeholder="Enter State Code of Place of Supply" value={buyer.pos || ""} name="pos" autoComplete="off" onChange={(e) => handleChange1(e)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Address1</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Address1" value={buyer.addr1 || ""} name="addr1" autoComplete="off" onChange={(e) => handleChange1(e)} />
                                </Form.Group>
                                {/* <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Address2</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Address2" value={buyer.addr2 || ""} name="addr2" autoComplete="off" onChange={(e) => handleChange1(e)} />
                                </Form.Group> */}
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Location</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Location" value={buyer.loc || ""} name="loc" autoComplete="off" onChange={(e) => handleChange1(e)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">PinCode </Form.Label>
                                    <Form.Control type="text" placeholder="Enter PinCode" value={buyer.pin || ""} name="pin" autoComplete="off" onChange={(e) => handleChange1(e)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">State Code</Form.Label>
                                    <Form.Control type="text" placeholder="Enter State Code" value={buyer.stcd || ""} name="stcd" autoComplete="off" onChange={(e) => handleChange1(e)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Phone No</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Phone No" value={buyer.ph || ""} name="ph" autoComplete="off" onChange={(e) => handleChange1(e)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Email ID</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Email ID" value={buyer.em || ""} name="em" autoComplete="off" onChange={(e) => handleChange1(e)} />
                                </Form.Group>
                                <h1>Eway Bill Details</h1>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Distance               
                                        
 <a   href="https://einvoice1.gst.gov.in/Others/GetPinCodeDistance"  className='btn btn-primary'  style={{   display: 'inline-block',    width: '147px',  height: '33px',   textDecoration: 'none' , background:"#1A42B7!important" }}target="_blank" rel="noopener noreferrer">
  <span style={{ position: 'relative', top: '-4px' }}>Distance</span>
</a></Form.Label>
                                    <Form.Control type="text" onChange={(e) => setdistance(e.target.value)} />
                                </Form.Group>
         
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Mode of Transport</Form.Label>
                                    <Select options={modes1} className='sett'onChange={(e) => settransMode(e.id)}  />

                                    {/* <Form.Control type="text" onChange={(e) => settransMode(e.target.value)} /> */}
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Transporter Id</Form.Label>
                                    <Select options={distributordata111} className='sett'  onInputChange={(inputValue) => setdisword11(inputValue)} onChange={handletrans}/>

                                    {/* <Form.Control type="text" onChange={(e) => settransId(e.target.value)} /> */}
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Transporter Name</Form.Label>
                                    <Form.Control type="text" value={transName} onChange={(e) => settransName(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Transport Document Date</Form.Label>
                                    <Form.Control type="date" onChange={(e) => settransDocDt1(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Transport Document No</Form.Label>
                                    <Form.Control type="text" onChange={(e) => settransDocNo(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Vehicle No</Form.Label>
                                    <Form.Control type="text" onChange={(e) => setvehNo(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Vehicle Type</Form.Label>
                                    <Select options={vechiletype}   className='sett'onChange={(e) => setvehType(e.id)}  />

                                    {/* <Form.Control type="text" onChange={(e) => setvehType(e.target.value)} /> */}
                                </Form.Group>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
      
        </Accordion>
        <br></br>
        <Row className="mb-1">
            <Form.Group as={Col} md="12" controlId="formBasicName">
                <Form.Label className="label">Product</Form.Label>
                <Select options={productdata} onChange={(e) => addData(e)} onInputChange={(e) => customFilter(e) } />

            </Form.Group>
        </Row>
        <br></br>
        <Table striped responsive hover size="sm">
            <thead className='p-3 mb-2 bg-success text-white'>
                <tr>
                    <th style={{ width: '20%' }}>PRODUCT&nbsp;NAME</th>
                    <th style={{ width: '9%' }}>HSN&nbsp;Code</th>
                    <th style={{ width: '8%' }}>GST</th>
                    <th style={{ width: '8%' }}>UOM</th>
                    <th style={{ width: '8%' }}>QTY(PCS)</th>
                    <th style={{ width: '8%' }}>QTY(KG)</th>
                    <th style={{ width: '8%' }}>RATE (₹)</th>
                    <th style={{ width: '8%' }}>UOM</th>
                    <th style={{ width: '8%' }}>GROSS AMOUNT(₹)</th>
                    <th style={{ width: '8%' }}>DISCOUNT %</th>
                    <th style={{ width: '8%' }}>NET AMOUNT (₹)</th>
                    {/* <th>Per&nbsp;Unit&nbsp;Rate</th> */}
                    <th style={{ width: '8%' }}>GST VALUE&nbsp;(₹)</th>
                    <th style={{ width: '8%' }}>TOTAL&nbsp;(₹)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                    {/* <th>View</th> */}
                    <th>Action&nbsp;&nbsp;</th>
                </tr>
            </thead>
            <tbody style={{ overflow: "scroll" }}>
                {purchaseReturnItems.map(item =>
                    <tr key={item.product.id}>
                        <td>
                            <Form.Group as={Col} md="12" >
                                {/* <Form.Control type="text" value={item.productName} size="sm" /> */}
                                <textarea type="text" className="form-control textcontrol" value={item.productName} size="sm" ></textarea>

                            </Form.Group>
                            <Form.Control as="textarea" onChange={(e) => { dispatch(changepurchasereturnupdatetext(e.target.value, item)) }} value={item.text} rows={2} className='form-control' />
                        </td>
                        <td>
                            <Form.Group as={Col} md="12">
                                <Form.Control type="number" value={item.hsncode} size="sm" readOnly />
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group as={Col} md="12">
                                <Form.Control type="number" value={item.igst} size="sm" readOnly />
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group as={Col} md="12">
                                <Form.Select disabled={item.unitofmeasurement == "box" ? true:item.productType !== "kgproduct" ? true:false } value={item.unitofmeasurement} onChange={(e) => { dispatch(purchasereturnupdatemeasurement(e.target.value, item, item.unitofmeasurement)); }} size="sm">
                                <option>{item.unitofmeasurement == "box" ? "PCS":item.productType !== "kgproduct" ? 'PCS':"Select"}</option>
                                    <option value="pcs">PCS</option>
                                    <option value="kg">KG</option>
                                </Form.Select>
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group as={Col} md="12">
                                <Form.Control type="number" min="0" onChange={(e) => { dispatch(purchasereturnupdateqtypcs(e.target.value, item, item.unitofmeasurement)); roundData(e.target.value)}}   value={item.purquantity} size="sm" />
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group as={Col} md="12">
                                <Form.Control type="number" min="0" onChange={(e) => { dispatch(purchasereturnupdateqtykgs(e.target.value, item, item.unitofmeasurement));roundData(e.target.value) }}  value={item.unitofmeasurement == "box" ? "" : item.purquantitykgs} size="sm" />
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group as={Col} md="12">
                                <Form.Control type="number" min="0" size="sm" onChange={(e) => { dispatch(changecostpurchasereturnupdate(e.target.value, item)); roundData(e.target.value)}} value={item.dlp} />
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group as={Col} md="12">
                                <Form.Select disabled={item.calunitofmeasurement == "box" ? true:item.productType !== "kgproduct" ? true:false } value={item.calunitofmeasurement} onChange={(e) => { dispatch(purchasereturnupdatecalmeasurement(e.target.value, item, item.calunitofmeasurement));roundData(e.target.value) }} size="sm">
                                    <option>{item.calunitofmeasurement == "box" ? "PCS":item.productType !== "kgproduct" ? 'PCS':"Select"}</option>
                                    <option value="pcs">PCS</option>
                                    <option value="kg">KG</option>
                                </Form.Select>
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group as={Col} md="12">
                                <Form.Control type="text" value={Number(item.grossamount)} size="sm" />
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group as={Col} md="12">
                                <Form.Control type="number" min="0" onChange={(e) => { dispatch(changepurchasereturnupdatediscount(e.target.value, item,)); roundData(e.target.value)}} value={item.tradeDiscount || ''} size="sm" />
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group as={Col} md="12">
                                <Form.Control type="text" value={Number(item.total)} size="sm" />
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group as={Col} md="12">
                                <Form.Control type="text" value={Number(item.gstvalue)} size="sm" />
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group as={Col} md="12">
                                <Form.Control type="text" value={Number(item.amount)} size="sm" />
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group as={Col} md="12">
                                <Form.Control type='button' className='btn btn-danger' onClick={() => {dispatch(removefromcartfrompurchasereturnupdate(item.product.id));roundData('')}} value="X" size="sm" />
                            </Form.Group>
                        </td>
                    </tr>
                )}
            </tbody>
             <tbody>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td style={{ fontSize: 13 }}>Packing&nbsp;&&nbsp;Forwarding</td>
                    <td>  
                        <Form.Group as={Col} md="12">
                        <Form.Control type="number" value={shippingcharge} onChange={(e) => settingship(e.target.value)} style={{ height: 30 }} />
                    </Form.Group>
                    </td>
                    <td></td>

                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td style={{ fontSize: 13 }}>Net&nbsp;Amount</td>
                    <td>
                        <Form.Group as={Col} md="12">
                        <Form.Control type="number" value={netAmount?((netAmount).toFixed(2)):"0"}  style={{ height: 30 }} />
                    </Form.Group>
                    </td>
                    <td></td>

                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td style={{ fontSize: 13 }}>IGST</td>
                    <td><Form.Group as={Col} md="12">
                        <Form.Control type="number" value={igstcheck()} requied style={{ height: 30 }}/>
                    </Form.Group></td>
                    <td></td>


                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td style={{ fontSize: 13 }}>SGST</td>
                    <td> <Form.Group as={Col} md="12">
                        <Form.Control type="number" value={sgstcheck()} requied style={{ height: 30 }}/>
                    </Form.Group></td>
                    <td></td>

                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td style={{ fontSize: 13 }}>CGST</td>
                    <td><Form.Group as={Col} md="12">
                        <Form.Control type="number" value={cgstcheck()} requied style={{ height: 30 }}/>
                    </Form.Group></td>
                    <td></td>


                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td style={{ fontSize: 13 }}>Round&nbsp;off&nbsp;Value</td>
                    <td><Form.Group as={Col} md="12">
                        <Form.Control type="number" value={round} onChange={(e) => settinground(e.target.value)} style={{ height: 30 }} />
                    </Form.Group></td>
                    <td></td>

                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td style={{ fontSize: 13 }}>Grand&nbsp;Total</td>
                    <td><Form.Group as={Col} md="12">
                        <Form.Control type="number" value={grandTotal?((grandTotal).toFixed(2)):"0"} readOnly style={{ height: 30 }}/>
                    </Form.Group></td>
                    <td></td>

                </tr>
            </tbody> 
        </Table>
        <Button variant="primary" type="button" onClick={submitdata}>
            Submit
        </Button>
    </Form>
</MDBCard>
  )
}
