import axios from "axios";
import { MDBCard } from "mdbreact";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../../services/Api";
import authHeader from "../../services/auth-header";
import AuthService from "../../services/auth.service";
import Swal from "sweetalert2";
import Select from "react-select";

export default function View(){
    const {id} = useParams();
    const { pointindex } = useParams();
const[assetsname,setassetsname] = useState('');
const[mrp,setmrp] = useState('');
const[srno,setsrno] = useState('');
const[remarks,setremarks] = useState('');
const[status,setstatus] = useState('');
const[expiryDate,setexpiryDate] = useState('');
const user = AuthService.getCurrentUser();
const [isLoading, setIsLoading] = useState(false);
const [invoiceNo, setInvoiceNo] = useState('');
const [invoiceDate, setInvoiceDate] = useState('');
const [insuranceProviderName, setInsuranceProviderName] = useState('');
const [insurancePolicyNo, setInsurancePolicyNo] = useState('');
const [ipnStartDate, setIpnStartDate] = useState('');
const [ipnEndDate, setIpnEndDate] = useState('');
const [insuranceFileName, setInsuranceFileName] = useState('');
const [insuranceFileLocation, setInsuranceFileLocation] = useState('');
const [supplier, setSupplier] = useState('');

const [inscFile, setInscFile] = useState(null);
const [inscFile2, setInscFile2] = useState(null);
const navigate = useNavigate();

const applyFontSizeTransition = (elementId) => {
  const element = document.getElementById(elementId);
  element.style.transition = 'font-size 2s';
  element.style.fontSize = '30px';
  element.style.fontWeight = '600';
  // font-weight: 600;
  setTimeout(() => {
     
    document.getElementById("typer1").classList.add('promote');
    element.style.fontSize = '24px';
  }, 1000);
};

useEffect(()=>{
    axios.get(BASE_URL+'asset/byid/'+id,{
        headers:authHeader()
    }).then(res=>{
      console.log(res);
      
      setassetsname(res.data.assetsname);
        setmrp(res.data.mrp);
        setsrno(res.data.srno);
        setremarks(res.data.remarks);
        setstatus(res.data.status);
        setexpiryDate(res.data.expiryDate);
        setInvoiceNo(res.data.invoiceno);
        setInvoiceDate(res.data.invoicedate)
        setInsuranceProviderName(res.data.insuranceprovidername);
        setInsurancePolicyNo(res.data.insurancepolicyno);
        setIpnStartDate(res.data.ipnstartdate);
        setIpnEndDate(res.data.ipnenddate);
        setInscFile2(res.data.insurancefilelocation)
        setSupplier([res.data.supplier].map(use => ({ value: use.id, label: use.companyname }))[0]);
    })
},[]);

// const defaultvalue = new Date(expiryDate);
// const default_Value = defaultvalue.toLocaleDateString('en-CA');

const handledelete=(id)=>{
  
     
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        setIsLoading(true); 
        axios.delete(BASE_URL + 'asset/delete/'+id,{
          headers:authHeader()
        }).then(res=>{
  
  
          navigate('/Assest/Table/1')
         
          Swal.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
          )
  
  
  
          // window.location.reload();
        }).catch(error => {
          console.error('Error adding  :', error);
          if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `
              <div>
                <p id="issueText">Why do I have this issue?</p>
                <ul>
            
                <li id="typer1">You might have an internet issue</li>
                </ul>
              </div>
            `,
            didOpen: () => {
              applyFontSizeTransition('issueText');
            }
          });
        }
        })
        .finally(() => {
          setIsLoading(false); 
        });
      
      }
    })
          }

    return(
        <MDBCard className="company">
                        <div className='d-flex bd-highlight'>

<div className="card-header card-title w-100">View Asset</div>
{user.roles[0] === 'ROLE_ADMIN' &&      
        
        <Link className='p-2 w-200 card-header card-title bd-highlight' variant="primary" style={{width:'auto'}} onClick={()=>handledelete(id)} disabled={isLoading}>
        
        <Button className="btn-sm">  {isLoading ? 'Loading...' : 'Delete'}</Button>
      </Link>
        }
<Link className='p-2 w-200 card-header card-title bd-highlight' to={`/Assest/Update/${id}/${pointindex}`}><Button>Update</Button></Link>
<Link className='p-2 w-200 card-header card-title bd-highlight' to={'/Assest/Table/1'}><Button>Back</Button></Link>
</div>
        <Form className="product1">
          <Form className="product1">
                <Row className="mb-1">
                <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Asset Name</Form.Label>
                        <Form.Control type="text" disabled name="assestname" value={assetsname} onChange={(e)=>setassetsname(e.target.value)} autoComplete="off" placeholder="Enter Assest Name"/>
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">MRP</Form.Label>
                        <Form.Control type="text" disabled name="mrp" value={mrp} onChange={(e)=>setmrp(e.target.value)} autoComplete="off" placeholder="Enter MRP"/>
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Warranty Till Date</Form.Label>
                        <Form.Control type="date" disabled value={expiryDate} name="expiryDate" onChange={(e)=>setexpiryDate(e.target.value)}/>
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Serial Number</Form.Label>
                        <Form.Control type="text" disabled name="srno" value={srno} onChange={(e)=>setsrno(e.target.value)} autoComplete="off" placeholder="Enter Serial Number"/>
                    </Form.Group>
                    <Form.Group as={Col} md="12" controlId="formBasicName">
                        <Form.Label className="label">Remarks</Form.Label>
                        <Form.Control as="textarea" disabled rows={3} value={remarks} name='remarks' onChange={(e)=>setremarks(e.target.value)} placeholder='Enter Remarks' autoComplete="off" />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Invoice Number</Form.Label>
                        <Form.Control type="text" disabled name="invoiceNo" value={invoiceNo} onChange={(e)=>setInvoiceNo(e.target.value)} autoComplete="off" placeholder="Enter MRP"/>
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Invoice Date</Form.Label>
                        <Form.Control type="date" disabled name="invoiceNo" value={invoiceDate} onChange={(e)=>setInvoiceDate(e.target.value)} autoComplete="off" placeholder="Enter MRP"/>
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Insurance Provider Name</Form.Label>
                        <Form.Control type="text" disabled name="invoiceNo" value={insuranceProviderName} onChange={(e)=>setInsuranceProviderName(e.target.value)} autoComplete="off" placeholder="Insurance Policy "/>
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Insurance Policy Number</Form.Label>
                        <Form.Control type="text" disabled name="Insurance Policy Number" value={insuranceProviderName} onChange={(e)=>setInsuranceProviderName(e.target.value)} autoComplete="off" placeholder="Insurance Policy Number"/>
                    </Form.Group>

                    <Form.Group as={Col} md="6" controlId="formIpnStartDate">
            <Form.Label className="label">IPN Start Date</Form.Label>
            <Form.Control type="date" value={ipnStartDate} disabled name="ipnStartDate" onChange={(e) => setIpnStartDate(e.target.value)} autoComplete="off" />
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formIpnEndDate">
            <Form.Label className="label">IPN End Date</Form.Label>
            <Form.Control type="date" value={ipnEndDate} disabled  name="ipnEndDate" onChange={(e) => setIpnEndDate(e.target.value)} autoComplete="off" />
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formSupplier">
            <Form.Label className="label">Supplier</Form.Label>
            
            <Select
  // options={dist} 
  isDisabled
  value={[{ value: [supplier].map((item)=>item.value) , label: [supplier].map((item)=>item.label) }]} 
  // onInputChange={(inputValue) => setdisword(inputValue)} 
  // onChange={(options) => onChangeSupplier(options)}
/>

            {/* <Form.Control type="text" name="supplier" onChange={(e) => setSupplier(e.target.value)} autoComplete="off" placeholder="Enter Supplier" /> */}
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formIpnEndDate">
            <Form.Label className="label">Insurance File</Form.Label>
            <a 
  href={`http://43.225.54.138:8080/scm/${inscFile2}`} 
  target="_blank" 
  rel="noopener noreferrer" 
  style={{
    display: 'inline-block',
    padding: '10px 20px',
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#fff',
    backgroundColor: '#007bff',
    border: 'none',
    borderRadius: '5px',
    textAlign: 'center',
    textDecoration: 'none',
    cursor: 'pointer'
  }}
>
  Open File
</a>
{/* <Form.Control type="sfile" name="inscFile" onChange={(e) => setInscFile(e.target.files[0])} autoComplete="off" /> */}

            {/* <Form.Control type="date" value={ipnEndDate}  name="ipnEndDate" onChange={(e) => setIpnEndDate(e.target.value)} autoComplete="off" /> */}
          </Form.Group>
                </Row>
                </Form>
            </Form>
    </MDBCard>
    )
}


